import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { ButtonGroup, CircularProgress, debounce, IconButton, Menu, MenuItem, Dialog, DialogContent, DialogActions, Popper, Box, ClickAwayListener } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import clsx from "clsx"
import { createContext, MutableRefObject, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Link, Location, useLocation } from "react-router-dom"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector, useQueryStructureId } from "../../app/hooks"
import { createConsequence, createDefect, createReason, deleteConsequence, deleteDefect, deleteReason, genConsequenceAI, genDefectAI, genReasonAI, RiskAnalysisConsequence, RiskAnalysisDefect, RiskAnalysisDocument, RiskAnalysisFunction, RiskAnalysisGroup, RiskAnalysisReason, updateConsequence, updateDefect, updateFunction, updateReason } from "../documents/riskAnalysis/riskAnalysisApi"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import './riskAnalysis.css'
import { findConsequence, findDefect, findFunction, findReason, isEditable } from "./utils"
import { DataSetterContext } from "../documents/riskAnalysis/RiskAnalysisForm"
import { JustText, useJustText } from "../../app/RichTextEditor"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Editor } from "@tiptap/react"
import { CommentsSection } from "./CommentsSection"
import { DocumentActions } from "../documents/documentsApi"
import { DocComment } from "../documents/commentsAPI"
import { ValidationsBrowser } from "../validations/ValidationsBrowser"
import { selectValidationById } from "../validations/validationsSlice"
import { CreateValidationForm } from "../validations/CreateValidation"
import { EditValidationForm } from "../validations/EditValidation"

const headerBgColor = 'bg-gray-100'
const borderColor = 'border-gray-300'

const itemWrapperClass = "my-2 border-solid rounded-3xl border-2 border-gray-500 p-4"

const FnContext = createContext<AppId>('')

const DocIdContext = createContext<AppId>('')

interface Limit{
    good : number
    warning: number
}
const LimitContext = createContext<Limit>({good:0, warning:0})

type CommentsSettings
    = { tag: 'no' }
    | { tag: 'yes', canAdd: boolean }

const CommentsContext = createContext<CommentsSettings>({ tag: 'no' })

const testSettings: CommentsSettings = {tag:'yes',canAdd:true}

interface WithComments {
    comments: DocComment[]
}

interface Editable {
    editable : boolean
}

type DialogState
    = { tag: 'none' }
    | { tag: 'assoc-test', fn: RiskAnalysisFunction }
    | { tag: 'create-test', fn: RiskAnalysisFunction }
    | { tag: 'edit-test', testId: AppId }

const DialogContext = createContext<(st: DialogState) => void>(() => {})

const empty = new Set([])

interface RiskAnalysisContentProps {
    doc : RiskAnalysisDocument
    actions: DocumentActions
}
export const RiskAnalysisContent = (props : RiskAnalysisContentProps & WithComments) => {
    const { doc, actions, comments } = props
    const dispatch = useAppDispatch()
    const { hash } = useLocation()
    const groupRefs = useRef<Map<string, HTMLDivElement>>(new Map())
    const gridRef = useRef<HTMLDivElement | null>(null)
    const updateData = useContext(DataSetterContext)

    const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })
    const [running, setRunning] = useState(false)
    const [testId, setTestId] = useState<AppId | undefined>(undefined)

    const selection: Set<AppId> = useMemo(() => testId ? new Set([testId]) : new Set(), [testId])
    
    const [goodThreshold, setGoodThreshold] = useState<number>(
        () => {
            const setting = doc?.settings.find(x => x.name === 'GOOD_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );

    const [warningThreshold, setWarningThreshold] = useState<number>(
        () => {
            const setting = doc?.settings.find(x => x.name === 'WARNING_THRESHOLD');
            return setting ? Number(setting.value) : 0;
        }
    );

    useEffect(() => {
        if (hash && gridRef.current) {
            const someId = hash.substring(1)
            if (groupRefs.current.has(someId)) {
                const div = groupRefs.current.get(someId) as HTMLDivElement
                const rect = div.getBoundingClientRect()
                gridRef.current.scrollBy({
                    top: rect.top - 208,
                    behavior: 'smooth',
                })
            }
        }
    }, [hash])

    const limit = useMemo( () => {
        return {good: goodThreshold, warning: warningThreshold}
    },[goodThreshold, warningThreshold ])

    const commentsCfg = useMemo(() => {
        if (actions.documentContentActions.findIndex(act => act === 'Comment') >= 0) {
            return {
                tag: 'yes',
                canAdd: true
            } as CommentsSettings
        } else {
            return {
                tag: 'no'
            } as CommentsSettings
        }
    }, [actions])

    const editable = isEditable(actions)

    const openDialog = useCallback((st: DialogState) => {
        setDialog(st)
    }, [])

    const handleAssocTestClick = async () => {
        if (testId && dialog.tag === 'assoc-test') {
            setRunning(true)
            const fn = dialog.fn
            const { defects, ...data } = fn
            try {
                const newFn = await dispatch(updateFunction({
                    ...data,
                    docId: doc.id,
                    testDefinitionId: testId,
                    isTestable: true,
                })).unwrap()
                updateData(draft => {
                    const someFn = findFunction(draft, fn.id)
                    if (someFn) {
                        someFn.testDefinitionId = newFn.testDefinitionId
                        someFn.isTestable = newFn.isTestable
                    }
                })
            } catch (error) {
                
            } finally {
                setRunning(false)
                setDialog({ tag: 'none' })
                setTestId(undefined)
            }
        }
    }

    const handleCloseDialogClick = () => {
        setDialog({ tag: 'none' })
        setTestId(undefined)
    }

    return <DocIdContext.Provider value={doc.id}>
            <LimitContext.Provider value={limit}>
                <CommentsContext.Provider value={commentsCfg}>
                    <DialogContext.Provider value={openDialog}>
                        <div className="flex flex-row gap-x-4">
                            <NavTree groups={doc.groups} />
                            <div className="basis-full">
                                <div ref={gridRef} id='risk-analysis-grid' className="border-solid border-gray-300 border">
                                    {doc.groups.map((item) => <Group key={item.guid} editable={editable} groupRefs={groupRefs} gr={item} comments={comments} />)}
                                </div>
                            </div>
                        </div>
                        {dialog.tag === 'create-test' && <CreateTestDialog fn={dialog.fn} />}
                        {dialog.tag === 'edit-test' && <EditTestDialog testId={dialog.testId} />}
                        <Dialog fullWidth maxWidth='lg' open={dialog.tag === 'assoc-test'}>
                            <DialogContent>
                                <ValidationsBrowser excluded={empty} selection={selection} itemChecked={id => setTestId(id)} itemUnchecked={id => setTestId(undefined)}></ValidationsBrowser> 
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton loading={running} onClick={handleAssocTestClick}>
                                    <Localized id="ok"><span>OK</span></Localized>
                                </LoadingButton>
                                <LoadingButton loading={running} onClick={handleCloseDialogClick}>
                                    <Localized id="cancel"><span>Anuluj</span></Localized>
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </DialogContext.Provider>
                </CommentsContext.Provider>
        </LimitContext.Provider>
    </DocIdContext.Provider>
}

interface GroupProps {
    gr : RiskAnalysisGroup
    groupRefs : MutableRefObject<Map<string, HTMLDivElement>>
}

const Group = (props : GroupProps & WithComments & Editable) => {
    const { gr, groupRefs, comments, editable } = props
    const ref = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        if (ref.current) {
            groupRefs.current.set(gr.id, ref.current)
        }
    }, [ref, gr])

    return <>
        <div ref={ref} id={gr.id} className={`border-b ${borderColor} border-solid border-x-0 border-t-0 col-span-2 px-2 py-2 text-lg font-bold ${headerBgColor}`}>{`${gr.numeration}. ${gr.name}`}</div>
        {gr.functions.map((item) => <Function key={item.id} editable={editable} fn={item} comments={comments} />)}
        {gr.groups.map((item) => <SubGroup groupRefs={groupRefs} key={item.guid} editable={editable} gr={item} comments={comments} />)}
    </>
}

const SubGroup = (props : GroupProps & WithComments & Editable) => {
    const { gr, groupRefs, comments, editable } = props
    const ref = useRef<HTMLDivElement | null>(null)

    useLayoutEffect(() => {
        if (ref.current) {
            groupRefs.current.set(gr.id, ref.current)
        }
    }, [ref, gr])

    return <>
        <div ref={ref} id={gr.guid} className={`border-b ${borderColor} border-solid border-x-0 border-t-0 col-span-2 px-2 py-2 font-bold ${headerBgColor}`}>{`${gr.numeration}. ${gr.name}`}</div>
        {gr.functions.map((item) => <Function key={item.id} editable={editable} fn={item} comments={comments} />)}
    </>
}

interface FunctionProps {
    fn : RiskAnalysisFunction
}
const Function = (props : FunctionProps & WithComments & Editable) => {
    const { fn, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)

    const handleCcmChange = () => {
        save(ccm, fn)
    }

    const ccm = useJustText({
        defaultValue: fn.currentControlMethod,
        onChange: handleCcmChange,
        editable,
    })

    const save = useCallback(debounce((ccmEditor: Editor | null, fn: RiskAnalysisFunction) => {
        const name = ccmEditor?.getHTML() ?? ''
        const { defects, ...data } = fn
        dispatch(updateFunction({
            ...data,
            currentControlMethod: name,
            docId,
        }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const someFn = findFunction(draft, fn.id)
                    if (someFn) {
                        someFn.currentControlMethod = name
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    return <>
        <div className="flex flex-row">
            <div className="ra-basis-20 border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Funkcja</div>
            <div className="ra-basis-65 ra-fn-details">
                <Header />
            </div>
            <div className="ra-basis-15 border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Test</div>
        </div>
        <Spacer />
        <div className="flex flex-row items-start">
            <div className={clsx("ra-basis-20 my-2 border-solid rounded-3xl border-2 border-gray-500 p-4")}>
            <div className='flex space-x-2'>
                <div className='text-xs bg-gray-300 py-1 px-2 grow-0 rounded-lg text-sm'>
                    {fn.numeration }
                </div>
            </div>
                <div className="text-xs overflow-y-auto" dangerouslySetInnerHTML={{ __html: fn.name ?? "" }}></div>
            </div>
            <div className="ra-basis-65 ra-fn-details">
                <FnContext.Provider value={fn.id}>
                    {fn.defects.length > 0 ? fn.defects.map((item, idx) => <>
                        <Defect key={item.id} editable={editable} defect={item} idx={idx} total={fn.defects.length} comments={comments} />
                        </>) : <NoDefect editable={editable} fnId={fn.id} />}
                </FnContext.Provider>
            </div>
            <div className="ra-basis-15 flex-column">
                <TestSection fn={fn} editable={editable} />
                <div className="mt-6 border-b border-gray-300 border-solid border-x-0 border-t border-l bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Obecne śr. kontroli</div>
                <div className={itemWrapperClass}>
                    <div className="text-xs">
                        <JustText editor={ccm} />
                    </div>
                </div>
            </div>
        </div>
        <HLine />
    </>
}

const NoDefect = ({ fnId, editable } : { fnId : AppId } & Editable) => {
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)

    const [saving, setSaving] = useState(false)

    const create = async () => {
        setSaving(true)
        try {
            const newDefect = await dispatch(createDefect({
                docId,
                name: '',
                functionId: fnId,
                value: 0,
            })).unwrap()
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId: newDefect.id,
                value: 0
            })).unwrap()
            newDefect.consequences.push(newConsequence)
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: ''
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    someFn.defects.push(newDefect)
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }
    const genAI = async () => {
        setSaving(true)
        try {
            const defects = await dispatch(genDefectAI({ docId, fnId })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    for (let i = 0; i < defects.length; i++) {
                        someFn.defects.push(defects[i])
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }

    return <>
        <div></div>
        <div className="place-self-center">
            <ButtonGroup>
                <LoadingButton disabled={!editable} loading={saving} variant='outlined' size='small' onClick={create}>+</LoadingButton>
                <LoadingButton disabled={!editable} loading={saving} variant='outlined' size='small' onClick={genAI}>AI</LoadingButton>
            </ButtonGroup>
        </div>
        <div className="col-span-6"></div>
    </>
}

const NoConsequence = ({ defectId, fnId }: { defectId: AppId, fnId: AppId }) => {
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const docId = useContext(DocIdContext)

    const [saving, setSaving] = useState(false)

    const create = async () => {
        setSaving(true)
        try {
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId,
                value: 0,
            })).unwrap()
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defectId)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences.push(newConsequence)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setSaving(false)
        }
    }

    return <>
        <div></div>
        <div className="place-self-center">
            <LoadingButton loading={saving} variant='outlined' size='small' onClick={create}>+</LoadingButton>
        </div>
        <div className="col-span-4"></div>
    </>
}

interface DefectProps {
    defect : RiskAnalysisDefect
    idx : number
    total : number
}
const Defect = (props: DefectProps & WithComments & Editable) => {
    const { defect, idx, total, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const functionId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(defect.name)
    const [freq, setFreq] = useState<number>(defect.value)

    const [generating, setGenerating] = useState(false)

    const save = useCallback(debounce((nameEditor: Editor | null, frequency: number) => {
        const name = nameEditor?.getHTML() ?? ''
        dispatch(updateDefect({ docId, id: defect.id, functionId, value: frequency, name }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, functionId)
                    if (fn) {
                        const someDefect = findDefect(fn, defect.id)
                        if (someDefect) {
                            someDefect.name = name
                            someDefect.value = frequency
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    const handleDefectChange = () => {
        save(name, freq)
    }
    const handleFreqChange = (newVal: number) => {
        setFreq(newVal)
        save(name, newVal)
    }

    const name = useJustText({
        defaultValue: defaultName,
        onChange: handleDefectChange,
        editable,
    })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu

    const handleAddNextDefect = async () => {
        setContextMenu(null)
        try {
            const newDefect = await dispatch(createDefect({
                docId,
                name: '',
                functionId,
                value: 0,
            })).unwrap()
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId: newDefect.id,
                value: 0,
            })).unwrap()
            newDefect.consequences.push(newConsequence)
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',

            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    someFn.defects.push(newDefect)
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally { }
    }

    const handleGenConsequenceAI = async () => {
        setContextMenu(null)
        setGenerating(true)
        try {
            const newConsequences = await dispatch(genConsequenceAI({
                docId,
                defectId: defect.id,
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defect.id)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences = newConsequences
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setGenerating(false)
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteDefect({ docId, defectId: defect.id }))
            setter(draft => {
                const someFn = findFunction(draft, functionId)
                if (someFn) {
                    const i = someFn.defects.findIndex(d => d.id === defect.id)
                    if (i >= 0) {
                        someFn.defects.splice(i, 1)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const Frequency = <Num editable={editable} value={freq} onChange={handleFreqChange} />

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={defect.guid} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={false} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextDefect}><Localized id='risk-analysis-add-defect'>Dodaj kolejną wadę</Localized></MenuItem>
                <MenuItem onClick={handleGenConsequenceAI}><Localized id='risk-analysis-ai-add-defect'>Zapytaj o skutki AI</Localized></MenuItem>
            </Menu>
        </div>
    const consequences = defect.consequences.length > 0
        ? defect.consequences.map((item, i) => 
            <Consequence
                key={item.id}
                editable={editable}
                consequence={item}
                defectId={defect.id}
                idx={i}
                total={defect.consequences.length}
                isLastDefect={idx === total - 1}
                f={freq}
                generatingConsequence={generating}
                comments={comments}
            />)
        : <NoConsequence defectId={defect.id} fnId={functionId} />

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine label={Frequency} /> : <Line label={Frequency} />}
                </div>
                {content}
                {consequences}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine label={Frequency} />
                </div>
                {content}
                {consequences}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LeftTLine label={Frequency} />
                </div>
                {content}
                {consequences}
            </>
    }
}

interface ConsequenceProps {
    consequence : RiskAnalysisConsequence
    defectId: AppId
    idx : number
    total : number
    isLastDefect : boolean
    f : number
    generatingConsequence: boolean
}
const Consequence = (props: ConsequenceProps & WithComments & Editable) => {
    const { consequence, defectId, idx, total, isLastDefect, f, generatingConsequence, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const fnId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(consequence.name)
    const [detection, setDetection] = useState<number>(consequence.value)

    const [generatingReason, setGeneratingReason] = useState(false)

    const save = useCallback(
      debounce((nameEditor: Editor | null, detection: number) => {
        const name = nameEditor?.getHTML() ?? ''
        dispatch(updateConsequence({ docId, id: consequence.id, defectId, value: detection, name }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, fnId)
                    if (fn) {
                        const someConsequence = findConsequence(fn, consequence.id)
                        if (someConsequence) {
                            someConsequence.name = name
                            someConsequence.value = detection
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
        }, 400), [])

    const handleConsequenceChange = () => {
        save(name, detection)
    }
    const handleDetectionChange = (newVal: number) => {
        setDetection(newVal)
        save(name, newVal)
    }

    const name = useJustText({ defaultValue: defaultName, onChange: handleConsequenceChange, editable })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu

    const handleAddNextConsequence = async () => {
        setContextMenu(null)
        try {
            const newConsequence = await dispatch(createConsequence({
                docId,
                name: '',
                defectId,
                value: 0,
            })).unwrap()
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId: newConsequence.id,
                value: 0,
                currentControlMethod: '',
            })).unwrap()
            newConsequence.reasons = [newReason]
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefectIdx = someFn.defects.findIndex(d => d.id === defectId)
                    if (someDefectIdx >= 0) {
                        someFn.defects[someDefectIdx].consequences.push(newConsequence)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally { }
    }

    const handleGenReasonsAI = async () => {
        setContextMenu(null)
        setGeneratingReason(true)
        try {
            const newReasons = await dispatch(genReasonAI({
                docId,
                consequenceId: consequence.id,
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequence.id)
                    if (someConsequence) {
                        someConsequence.reasons = newReasons
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
            setGeneratingReason(false)
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteConsequence({ docId, id: consequence.id }))
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someDefect = findDefect(someFn, defectId)
                    if (someDefect) {
                        const i = someDefect.consequences.findIndex(d => d.id === consequence.id)
                        if (i >= 0) {
                            someDefect.consequences.splice(i, 1)
                        }
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative ')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={consequence.guid} />}
            {generatingConsequence && <CircularProgress sx={{
                position: 'absolute',
                top: 'calc(50% - 20px)',
                left: 'calc(50% - 20px)',
            }} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={idx === 0} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextConsequence}><Localized id=''>Dodaj kolejny skutek</Localized></MenuItem>
                <MenuItem onClick={handleGenReasonsAI}><Localized id='risk-analysis-ai-add-defect'>Zapytaj o przyczyny AI</Localized></MenuItem>
            </Menu>
        </div>
    const reasons = consequence.reasons.map((item, i) => 
        <Reason
            key={item.id}
            editable={editable}
            reason={item}
            consequenceId={consequence.id}
            idx={i}
            total={consequence.reasons.length}
            isLastDefect={isLastDefect}
            isLastReason={idx === total - 1}
            f={f}
            d={detection}
            generating={generatingConsequence || generatingReason}
            comments={comments}
        />
    )

    const Detection = <Num editable={editable} value={detection} onChange={handleDetectionChange}/>

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine label={Detection} /> : <Line label={Detection} />}
                </div>
                {content}
                {reasons}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine label={Detection} />
                </div>
                {content}
                {reasons}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                        <LeftTLine label={Detection} />
                </div>
                {content}
                {reasons}
            </>
    }
}

interface ReasonProps {
    reason: RiskAnalysisReason
    consequenceId: AppId
    idx: number
    total : number
    isLastDefect : boolean
    isLastReason : boolean
    f : number
    d : number
    generating : boolean
}
const Reason = (props: ReasonProps & WithComments & Editable) => {
    const { reason, consequenceId, idx, total, isLastDefect, isLastReason, f, d, generating, comments, editable } = props
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const fnId = useContext(FnContext)
    const docId = useContext(DocIdContext)
    const commentsSetting = useContext(CommentsContext)
    const showComments = commentsSetting.tag === 'yes'

    const [defaultName] = useState(reason.name)
    const [severity, setSeverity] = useState<number>(reason.value)
    const ccmRef = useRef<HTMLTextAreaElement | null>(null)

    const save = useCallback(debounce((nameEditor: Editor | null, severity: number, currentControlMethod: string) => {
        const name = nameEditor?.getHTML() ?? ''
        dispatch(updateReason({ docId, id: reason.id, consequenceId, value: severity, name, currentControlMethod }))
            .then(unwrapResult)
            .then(() => {
                dispatch(showSuccess('saved'))
                setter(draft => {
                    const fn = findFunction(draft, fnId)
                    if (fn) {
                        const someR = findReason(fn, reason.id)
                        if (someR) {
                            someR.currentControlMethod = currentControlMethod
                            someR.name = name
                            someR.value = severity
                        }
                    }
                })
            })
            .catch(() => dispatch(showError('error')))
    }, 400), [])

    const handleReasonChange = () => {
        const ccm = ccmRef.current?.value ?? ''
        save(name, severity, ccm)
    }
    const handleSeverityChange = (newVal: number) => {
        const ccm = ccmRef.current?.value ?? ''
        setSeverity(newVal)
        save(name, newVal, ccm)
    }

    const name = useJustText({ defaultValue: defaultName, onChange: handleReasonChange, editable })

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu
    
    const handleAddNextReason  = async () => {
        setContextMenu(null)
        try {
            const newReason = await dispatch(createReason({
                docId,
                name: '',
                consequenceId,
                value: 0,
                currentControlMethod: ''
            })).unwrap()
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequenceId)
                    if (someConsequence) {
                        someConsequence.reasons.push(newReason)
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const handleDelete = async () => {
        setContextMenu(null)
        try {
            await dispatch(deleteReason({ docId, id: reason.id }))
            setter(draft => {
                const someFn = findFunction(draft, fnId)
                if (someFn) {
                    const someConsequence = findConsequence(someFn, consequenceId)
                    if (someConsequence) {
                        const i = someConsequence.reasons.findIndex(c => c.id === reason.id)
                        if (i >= 0) {
                            someConsequence.reasons.splice(i, 1)
                        }
                    }
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const content = <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, 'cursor-context-menu relative ')}>
            <div className="text-xs">
                <JustText editor={name} />
            </div>
            {showComments && <CommentsSection comments={comments} documentId={docId} sectionGuid={reason.guid} />}
            {generating && <CircularProgress sx={{
                position: 'absolute',
                top: 'calc(50% - 20px)',
                left: 'calc(50% - 20px)',
            }} />}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
            >
                <MenuItem disabled={idx === 0} onClick={handleDelete}><Localized id='delete'>Usuń</Localized></MenuItem>
                <MenuItem onClick={handleAddNextReason}><Localized id=''>Dodaj kolejna przyczyne</Localized></MenuItem>
            </Menu>
        </div>

    const Severity = <Num editable={editable} value={severity} onChange={handleSeverityChange} />
    const p = <P f={f} d={d} s={severity} />

    switch (idx) {
        case 0:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {total > 1 ? <TLine label={Severity} /> : <Line label={Severity} />}
                </div>
                {content}
                {p}
                {/* {comment} */}
            </>
        case total - 1:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastReason ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LLine label={Severity} />
                </div>
                {content}
                {p}
                {/* {comment} */}
            </>
        default:
            return <>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastDefect ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    {isLastReason ? null : <VLine />}
                </div>
                <div className=""></div>
                <div className=" grid grid-cols-2 gap-0 h-full">
                    <LeftTLine label={Severity} />
                </div>
                {content}
                {p}
                {/* {comment} */}
            </>
    }
}

const Header = () => {
    return <>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 text-xs font-bold flex justify-end items-center">Częstotliwość</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 py-1 text-xs font-bold flex justify-center items-center">Potencjalna wada</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-end items-center">Detekcja</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">Skutek</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 text-xs font-bold flex justify-end items-center">Srogość</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">Przyczyna</div>
        <div className=" border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 px-2 text-xs font-bold flex justify-center items-center">P</div>
    </>
}

interface LineProps {
    label? : ReactNode
}

const Line = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed"></div>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed relative">
            {props.label}
        </div>
        <div></div>
        <div></div>
    </>
}

const LLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className="relative">
            {props.label}
        </div>
        <div></div>
        <div className="border-gray-500 border-t-2 border-x-0 border-b-0 border-dashed"></div>
    </>
}

const TLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed"></div>
        <div className="border-gray-500 border-b-2 border-x-0 border-t-0 border-dashed relative">
            {props.label}
        </div>
        <div className="border-gray-500 border-r-2 border-l-0 border-dashed border-y-0"></div>
        <div></div>
    </>
}

const LeftTLine = (props: LineProps) => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className="relative">
            {props.label}
        </div>
        <div className="border-gray-500 border-dashed border-y-0 border-r-2 border-l-0"></div>
        <div className="border-gray-500 border-t-2 border-x-0 border-b-0 border-dashed"></div>
    </>
}

const VLine = () => {
    return <>
        <div className="border-gray-500 border-r-2 border-y-0 border-l-0 border-dashed"></div>
        <div className=""></div>
        <div className="border-gray-500 border-r-2 border-l-0 border-dashed border-y-0"></div>
        <div></div>
    </>
}

const HLine = () => {
    return <div className="col-span-2 h-4 border-gray-300 border-solid border-x-0 border-t-0 border-b"></div>
}

const Spacer = () => {
    return <div className="h-4"></div>
}

interface PProps {
    f : number
    d : number
    s : number
}
const P = (props: PProps) => {
    const { f, d, s} = props
    const {good, warning } = useContext(LimitContext)
    const value = f * d * s
    const cls = clsx(' place-self-center', 
        value <= good && 'text-green-600 font-bold bg-green-100 py-1 px-2 rounded',
        value <= warning && value > good && 'text-orange-600 font-bold bg-orange-100 py-1 px-2 rounded',
        value > warning && 'text-red-600 font-bold bg-red-100 py-1 px-2 rounded',
    )
    return <div className={cls}>{value}</div>
}

interface NavTreeProps {
    groups: RiskAnalysisGroup[]
}
const NavTree = (props: NavTreeProps) => {
    const { groups } = props
    const location = useLocation()

    const [collapsed, setCollapsed] = useState(true)

    const width = collapsed ? 'basis-8' : 'basis-96'

    return <div className={clsx(width)}>
        <nav className=" border-solid border-gray-300 border">
            <div className="border-b border-gray-300 border-solid border-x-0 border-t-0 bg-gray-100 py-1 px-2 text-xs font-bold flex justify-start items-center relative">
                <span className={clsx(collapsed && 'ra-groups-collapsed my-8')}>Grupy</span>
                {collapsed === false && <IconButton sx={{
                        height: '16px',
                        width: '16px',
                        position: 'absolute',
                        right: 'calc(0% + 4px)',
                    }}
                    onClick={() => setCollapsed(true)}
                ><ChevronLeftIcon /></IconButton>}
                {collapsed === true && <IconButton sx={{
                        height: '16px',
                        width: '16px',
                        position: 'absolute',
                        right: 'calc(0% + 8px)',
                        top: 'calc(0% + 4px)',
                    }}
                    onClick={() => setCollapsed(false)}
                ><ChevronRightIcon /></IconButton>}
            </div>
            {collapsed === false && <ol className="list-none pl-4">
                {groups.map((item) => <>
                    <li>
                        <DocLink to={{...location, hash: item.id}} >{`${item.numeration}. ${item.name}`}</DocLink>
                    </li>
                    <li>
                        <ol className="list-none pl-4">
                            {item.groups.map((subItem) => <>
                                <li>
                                    <DocLink to={{...location, hash: subItem.id}} >{`${subItem.numeration}. ${subItem.name}`}</DocLink>
                                </li>
                            </>)}
                        </ol>
                    </li>
                </>)}
            </ol>}
        </nav>
    </div>
}

interface DocLinkProps {
    to: Location<any>
    children : ReactNode
}
const DocLink = ({ children, to }: DocLinkProps) => {
    return <Link className="no-underline text-black" replace={true} to={to}>{children}</Link>
}

interface NumProps {
    value: number
    onChange : (newVal: number) => void
}
const Num = (props: NumProps & Editable) => {
    const { editable } = props
    const { value, onChange } = props
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLInputElement>(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
        setOpen(st => !st)
    }
    const css = 'absolute right-2 bottom-1 w-6 text-xs cursor-pointer border border-solid rounded border-gray-200 shadow px-2'

    if (editable) {
        return <ClickAwayListener onClickAway={() => { setOpen(false); }}>
            <div className={css} onClick={handleClick}>
                <span>{props.value}</span>
                <Popper open={open} anchorEl={anchorEl}>
                    <Box sx={{
                        fontSize: '0.75rem',
                        p: 0,
                        bgcolor: 'var(--gray-50)',
                        border: '1px solid var(--gray-100)',
                        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                        borderRadius: 0.5,
                        ['& ul']: {
                            m: 0,
                            p: 0,
                            listStyleType: 'none',
                            ['& li']: {
                                px: 3,
                                cursor: 'pointer',
                                position: 'relative',
                            },
                            ['& li:hover']: {
                                bgcolor: 'var(--gray-200)',
                            },
                            ['& li.current::before']: {
                                content: '"✓"',
                                position: 'absolute',
                                left: '5px',
                            },
                        },
                    }}>
                        <ul>
                            <li className={clsx(value === 1 && 'current')} onClick={() => onChange(1)}>1</li>
                            <li className={clsx(value === 2 && 'current')} onClick={() => onChange(2)}>2</li>
                            <li className={clsx(value === 3 && 'current')} onClick={() => onChange(3)}>3</li>
                            <li className={clsx(value === 4 && 'current')} onClick={() => onChange(4)}>4</li>
                            <li className={clsx(value === 5 && 'current')} onClick={() => onChange(5)}>5</li>
                        </ul>
                    </Box>
                </Popper>
            </div>
        </ClickAwayListener>
    } else {
        return <div className={css}>
            <span>{props.value}</span>
        </div>
    }

}

interface TestSectionProps {
    fn: RiskAnalysisFunction
}
const TestSection = (props : TestSectionProps & Editable) => {
    const { fn, editable } = props
    const dispatch = useAppDispatch()
    const docId = useContext(DocIdContext)
    const setter = useContext(DataSetterContext)
    const dialog = useContext(DialogContext)

    // Context menu
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        if (editable) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                    // Other native context menus might behave different.
                    // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            )
        }
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    // Context menu
    
    const handleUntestableClick = async () => {
        const { defects, ...data } = fn
        try {
            setContextMenu(null)
            const updated = await dispatch(updateFunction({
                ...data,
                isTestable: false,
                docId,
            })).unwrap()
            setter(draft => {
             const someFn = findFunction(draft, fn.id)
                if (someFn) {
                    someFn.isTestable = updated.isTestable
                }
            })
        } catch (error) {
            dispatch(showError('error'))
        } finally {
        }
    }

    const handleAssocTestClick = () => {
        setContextMenu(null)
        dialog({
            tag: 'assoc-test',
            fn,
        })
    }

    const handleCreateNewTestClick = () => {
        setContextMenu(null)
        dialog({ tag: 'create-test', fn })
    }

    const handleTestOpenClick = useCallback(() => {
        setContextMenu(null)
        dialog({ tag: 'edit-test', testId: fn.testDefinitionId ?? '' })
    }, [])

    const empty = fn.isTestable && fn.testDefinitionId === null
    let content = <></>
    if (empty) {
        content = <EmptyTest fn={fn} />
    } else if (!fn.isTestable) {
        content = <FnNotTestable />
    } else {
        content = <TestSelected testId={fn.testDefinitionId ?? ''} />
    }
    return <div onContextMenu={handleContextMenu} className={clsx(itemWrapperClass, "text-xs flex justify-center", editable && 'cursor-context-menu')}>
        {content}
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined }
        >
            {fn.testDefinitionId !== null && <MenuItem onClick={handleTestOpenClick}><Localized id=''>Otwórz</Localized></MenuItem>}
            <MenuItem onClick={handleCreateNewTestClick}><Localized id=''>Dodaj nowy test</Localized></MenuItem>
            <MenuItem onClick={handleAssocTestClick}><Localized id=''>Skojarz z testem</Localized></MenuItem>
            <MenuItem onClick={handleUntestableClick}><Localized id=''>Funkcja nietestowalna</Localized></MenuItem>
        </Menu>
    </div>
}

const EmptyTest = (props: TestSectionProps) => {
    return <>
        <span className='text-red-600 font-bold mr-1'>&#33;</span>
        <span className='text-gray-400'>Brak testu.</span>
    </>
}
const FnNotTestable = () => {
    return <>n/d</>
}
const TestSelected = ({ testId }: { testId: AppId }) => {
    const test = useAppSelector(st => selectValidationById(st, testId))
    const dialog = useContext(DialogContext)

    const handleTestDbClick = useCallback(() => {
        dialog({ tag: 'edit-test', testId })
    }, [])

    return <div className='flex flex-col gap-2 items-start w-full'>
        <div onDoubleClick={handleTestDbClick}>{test?.description ?? ''}</div>
        <div className='bg-gray-300 py-1 px-2 grow-0 rounded-lg'>{test?.stage ?? ''}</div>
    </div>
}

const CreateTestDialog = (props: { fn: RiskAnalysisFunction }) => {
    const { fn } = props
    const docId = useContext(DocIdContext)
    const dispatch = useAppDispatch()
    const setter = useContext(DataSetterContext)
    const dialog = useContext(DialogContext)
    const structureId = useQueryStructureId()

    const [newTestId, setNewTestId] = useState<AppId | undefined>(undefined)

    const close = useCallback(() => dialog({ tag: 'none' }), [])
    const handleTestCreated = useCallback((testId: AppId) => {
        // close()
        dispatch(updateFunction({
            ...fn,
            docId,
            testDefinitionId: testId,
            isTestable: true,
        })).then(() => {
            setter(draft => {
                const someFn = findFunction(draft, fn.id)
                if (someFn) {
                    someFn.testDefinitionId = testId
                    someFn.isTestable = true
                }
            })
            setNewTestId(testId)
        })
    }, [])

    return <Dialog fullScreen open={true}>
        <DialogContent>
            {newTestId ?
                <EditValidationForm id={newTestId} onClose={close} onDeleted={() => {}} /> :
                <CreateValidationForm onSuccess={handleTestCreated} onCancel={close} defName={fn.name} defStructureId={structureId} />}
        </DialogContent>
    </Dialog>
}

const EditTestDialog = (props: { testId: AppId }) => {
    const { testId } = props
    const dialog = useContext(DialogContext)

    const close = useCallback(() => dialog({ tag: 'none' }), [])
    const deleted = useCallback(() => {
        dialog({ tag: 'none' })
    }, [])

    return <Dialog fullScreen open={true}>
        <DialogContent>
            <EditValidationForm id={testId} onDeleted={deleted} onClose={close} />
        </DialogContent>
    </Dialog>
}
