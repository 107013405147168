import { ReactNode } from "react"
import { UrsOffer } from "../ursOfferSlice"
import { UrsOfferStatusLabel } from "../UrsOfferStatusLabel"
import { Localized } from "@fluent/react"
import { formatDate, parseISODate } from "../../../app/Utils"
import { StructureName } from "../../device/Structure"

interface HeaderFieldProps {
    children: ReactNode
}
const HeaderField = (props: HeaderFieldProps) => {
    return <div className="px-2 py-1 text-lg">{props.children}</div>
}
interface HeaderLabelProps {
    children: ReactNode
}
const HeaderLabel = (props: HeaderLabelProps) => {
    return <div className="text-xs font-bold px-2 py-1 bg-gray-100">{props.children}</div>
}


export const SupplierUrsOfferHeader = ({ ursOffer }: { ursOffer: UrsOffer }) => {
    const { ursNo, deviceTypeId, supplierName, companyName, status, createDate } = ursOffer

    const getColor = (status) => {
        switch (status) {
            case 'N':
                return 'bg-green-200';
            default:
                return 'bg-red-200';
        }
    };

    return <>
        <div className="grid grid-cols-6 border-solid border rounded border-gray-300 shadow">
            <HeaderLabel><Localized id="urs-number"><span>Symbol dokumentu</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="urs-device-type"><span>Typ urządzenia</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="urs-offer-client"><span>Klient</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="urs-offer-supplier"><span>Dostawca</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="urs-offer-create-date"><span>Data oferty</span></Localized></HeaderLabel>
            <HeaderLabel><Localized id="urs-offer-status"><span>Status</span></Localized></HeaderLabel>

            <HeaderField>{ursNo}</HeaderField>
            <HeaderField><StructureName structureId={deviceTypeId} /></HeaderField>
            <HeaderField>{companyName}</HeaderField>
            <HeaderField>{supplierName}</HeaderField>
            <HeaderField>{formatDate(parseISODate(createDate), false)}</HeaderField>
            <HeaderField>
                <span className={`inline-block px-2 py-1 rounded bg-red-100 ${getColor(status)}`}>
                    <UrsOfferStatusLabel status={status} />
                </span>
            </HeaderField>
        </div>
    </>
}
