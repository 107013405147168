import { Accordion, AccordionDetails, AccordionSummary, Alert, AppBar, Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material"
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers"
import { useParams } from "react-router-dom"
import { BusinessCaseStatus, BusinessCaseWithDetails, createBusinessCase, defaultBusinessCase, deleteBusinessCase, loadBusinessCase, updateBusinessCase } from "./businessCaseSlice"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { NPV } from "./NetPresentValue"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { AppData, AppDataProvider } from "../../app/AppDataProvider"
import { LoadingButton } from "@mui/lab"
import { parseISODate } from "../../app/Utils"
import { APIError, AppId } from "../../app/appTypes"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ServerErrorMsg } from "../../app/ServerErrorMsg"
import { If } from "../../app/If"
import { nanoid } from "@reduxjs/toolkit"
import { AppRequiredMessage } from "../../app/AppRequiredMessage"
import { AppBlobsProvider, BlobsProps } from "../../app/AppBlobsProvider"
import DownloadIcon from '@mui/icons-material/Download'
import { Localized, useLocalization } from "@fluent/react"
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { ProjectsBrowser } from "../project/ProjectsBrowser"
import { useGridApiRef } from "@mui/x-data-grid"
import { useAppSelector, useQueryProjectId } from "../../app/hooks"
import { selectProjectById } from "../project/projectSlice"
import ClearIcon from "@mui/icons-material/Clear"
import { useNavigate } from "react-router-dom"
import { BusinessCasePrinter } from "./BusinessCasePrinter"

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const statuses: BusinessCaseStatus[] = ['DRAFT', 'ON_HOLD', 'APPROVED']
const categories =
    ['bc-proj-cat-efficiency'
        , 'bc-proj-cat-compliance'
        , 'bc-proj-cat-health-and-safety'
        , 'bc-proj-cat-restoration'
        , 'bc-proj-cat-audit-action'
        , 'bc-proj-cat-cost-reduction'
        , 'bc-proj-cat-others'
    ]

export const EditBusinessCase = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''

    return <AppDataProvider id={id} def={defaultBusinessCase} create={createBusinessCase} update={updateBusinessCase} load={loadBusinessCase} form={Form} del={deleteBusinessCase} />
}

export const CreateBusinessCase = () => {
    return <AppDataProvider id={undefined} def={defaultBusinessCase} create={createBusinessCase} update={updateBusinessCase} load={loadBusinessCase} form={Form} del={deleteBusinessCase} />
}

const Form = (props: AppData<AppId, BusinessCaseWithDetails>) => {
    const { data, updateDataLocally, persisted, save, saving, unsaved, del } = props
    const navigate = useNavigate()
    const { l10n } = useLocalization()
    const [error, setError] = useState<APIError | null>(null)
    const [validationOn, setValidationOn] = useState<boolean>(false)
    const [projectsDialogOpen, setProjectsDialogOpen] = useState<boolean>(false);
    const [isPrintDialogVisible, setPrintDialogVisible] = useState<boolean>(false);

    const projectId = useQueryProjectId();
    if (projectId && !isNaN(Number(projectId))) updateDataLocally(d => d.projectId = Number(projectId));
    const project = useAppSelector(state => projectId && !isNaN(Number(projectId)) ? selectProjectById(state, projectId) : null);

    const deadline: Date | null = data.deadline ? parseISODate(data.deadline) : null
    const approval: Date | null = data.approvalDate ? parseISODate(data.approvalDate) : null

    const projectsGridRef = useGridApiRef();

    const onConfirmClick = () => {
        projectsGridRef.current.getSelectedRows().forEach((f) => {
            updateDataLocally(d => { d.projectId = f.id; d.projectName = f.name });
        })
        setProjectsDialogOpen(false);
    }

    const handleDeleteRelatedProjectClick = () => {
        updateDataLocally(d => { d.projectId = null; d.projectName = null });
    }

    const handleCloseDialog = () => {
        setPrintDialogVisible(false);
    };


    const handleDeadlineDateChange = (date: Date | null, c: PickerChangeHandlerContext<DateValidationError>) => {
        if (date === null || c.validationError !== null) {
            updateDataLocally(d => d.deadline = '')
        } else {
            updateDataLocally(d => d.deadline = date.toISOString())
        }
    }
    const handleApprovalDateChange = (date: Date | null, c: PickerChangeHandlerContext<DateValidationError>) => {
        if (date === null || c.validationError !== null) {
            updateDataLocally(d => d.approvalDate = '')
        } else {
            updateDataLocally(d => d.approvalDate = date.toISOString())
        }
    }
    const handleOpenPresentationClick = () => {
        setPrintDialogVisible(true)
    }
    const handleStartProjectClick = () => {
        navigate(`/projects/create?businessCaseId=${data.id}`);
    }
    const handleAddRiskClick = () => {
        updateDataLocally(d => d.risks.push({
            id: 0,
            clientId: nanoid(),
            name: 'Please enter here...',
            businessCaseId: data.id,
        }))
    }
    const handleAddOpportunityClick = () => {
        updateDataLocally(d => d.opportunities.push({
            id: 0,
            clientId: nanoid(),
            name: 'Please enter here...',
            businessCaseId: data.id,
        }))
    }
    const handleSaveClick = async () => {
        setValidationOn(true)
        if (data.title) {
            const result = await save()
            if (!persisted && result.tag === 'ok') {
                navigate(`/businessCase/edit/${result.entity.id}`, { replace: true })
            } else if (result.tag === 'error') {
                setError(result.error)
            }
        }
    }

    const NPVSection = useMemo(() => {
        return () => <NPV
            businessCaseId={data.id ? Number.parseInt(data.id) : 0}
            investementCost={data.investementCost}
            forcastedYearlySaving={data.forcastedYearlySaving}
            growthYearlyFactor={data.growthYearlyFactor}
            discountRate={data.discountRate}
            numberOfYears={data.numberOfYears}
            updateDataLocally={updateDataLocally}
            mode={data.mode}
            cashflows={data.cashflows}
            pv={data.pv ?? 0}
            npv={data.npv ?? 0}
            irr={data.irr ?? undefined}
            payback={data.payback}
        />
    }, [data.id])

    return (
        <section className="w-full max-w-6xl mx-auto py-6 md:py-6 lg:py-6">
            <div className="space-y-6 mb-4">
                <div className="flex items-center justify-between">
                    <ServerErrorMsg err={error} />
                    <div className="space-y-2">
                        <h2 className="text-2xl font-bold">Business Case</h2>
                        <If condition={persisted === true}>
                            <div className="flex items-center space-x-2">
                                <Autocomplete
                                    disablePortal
                                    options={statuses}
                                    value={data.status}
                                    onChange={(e, v) => updateDataLocally(d => d.status = v)}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params}
                                        label={<Localized id='bc-status'>Status</Localized>}
                                        InputLabelProps={{ shrink: true }}
                                    />}
                                />
                            </div>
                        </If>
                    </div>
                </div>
                <If condition={!projectId}>
                    <div className="space-y-2">
                        <Button onClick={() => setProjectsDialogOpen(true)} disabled={data.status === "APPROVED"}>
                            <Localized id="bc-link-project">Powiąż projekt</Localized>
                        </Button>
                    </div>
                </If>
                <If condition={data.projectId !== null || project !== null}>
                    <div className="space-y-2">
                        <TextField fullWidth id="project"
                            label={<Localized id='bc-related-project'>Project</Localized>}
                            value={data.projectName || project?.name}
                            aria-readonly
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleDeleteRelatedProjectClick}
                                            edge="end"
                                            aria-label="clear"
                                            disabled={data.status === "APPROVED"}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </div>
                </If>
                <div className="space-y-2">
                    <TextField fullWidth id="title"
                        label={<Localized id='bc-title'>Title</Localized>}
                        placeholder={l10n.getString('bc-title-placeholder', null, 'Enter the title of the business case')}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => updateDataLocally(d => d.title = e.target.value)} value={data.title}
                        error={validationOn && !data.title}
                        helperText={validationOn && !data.title ? <AppRequiredMessage /> : null}
                    />
                </div>
                <If condition={persisted === true}>
                    <div className="space-y-2">
                        <TextField fullWidth id="description"
                            label={<Localized id='bc-description'>Description</Localized>}
                            placeholder={l10n.getString('bc-description-placeholder', null, 'Provide a detailed description of the business case')}
                            multiline minRows={3} InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.description = e.target.value)} value={data.description}
                        />
                    </div>
                    <div className="space-y-2">
                        <TextField fullWidth id="current-situation"
                            label={<Localized id='bc-current-situation'>Bieżąca sytuacja</Localized>}
                            placeholder={l10n.getString('bc-current-situation-placeholder', null, 'Describe the current situation that this business case aims to address')}
                            multiline minRows={3} InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.currentSituation = e.target.value)} value={data.currentSituation}
                        />
                    </div>
                    <div className="space-y-2">
                        <TextField fullWidth id="goal"
                            label={<Localized id='bc-goal'>Goal</Localized>}
                            placeholder={l10n.getString('bc-goal-placeholder', null, 'Define the goal of the business case')}
                            multiline minRows={3} InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.goal = e.target.value)} value={data.goal}
                        />
                    </div>
                    <div className="space-y-2">
                        <TextField fullWidth id="success-criteria"
                            label={<Localized id='bc-success-criteria'>Success Criteria</Localized>}
                            placeholder={l10n.getString('bc-success-criteria-placeholder', null, 'Define the criteria for a successful outcome')}
                            multiline minRows={3} InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.successCriteria = e.target.value)} value={data.successCriteria}
                        />
                    </div>
                    <div className="space-y-2">
                        <TextField fullWidth id="alternative-solutions"
                            label={<Localized id='bc-alternative-solution'>Alternative Solutions</Localized>}
                            placeholder={l10n.getString('bc-alternative-solution-placeholder', null, 'List any alternative solutions that were considered')}
                            multiline minRows={3} InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.alternativeSolutions = e.target.value)} value={data.alternativeSolutions}
                        />
                    </div>
                    <div className="space-y-2">
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Localized id='bc-npv-section'>Net present value (NPV) section</Localized>
                            </AccordionSummary>
                            <AccordionDetails>
                                <NPVSection />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div className="space-y-2">
                        <TextField id="cost-center-code"
                            label={<Localized id='bc-cost-center'>Cost Center code</Localized>}
                            placeholder={l10n.getString('bc-cost-center-placeholder', null, 'Enter the cost center code')}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => updateDataLocally(d => d.costCenterCode = e.target.value)} value={data.costCenterCode}
                        />
                    </div>
                    <div className="space-y-2">
                        <DatePicker
                            label={<Localized id='bc-deadline'>Deadline date</Localized>}
                            value={deadline} onChange={handleDeadlineDateChange} />
                    </div>
                    <div className="space-y-2">
                        <DatePicker
                            label={<Localized id='bc-approval-date'>Approval date</Localized>}
                            value={approval} onChange={handleApprovalDateChange} />
                    </div>
                    <div className="space-y-2">
                        <Autocomplete
                            disablePortal
                            options={categories}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params}
                                label={<Localized id='bc-project-category'>Project category</Localized>}
                                InputLabelProps={{ shrink: true }}
                            />}
                            getOptionLabel={v => l10n.getString(v, null, v)}
                            value={data.projectCategory}
                            onChange={(e, v) => updateDataLocally(d => d.projectCategory = v)}
                        />
                    </div>
                    <div className="space-y-2">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <h3 className="text-lg font-medium">
                                    <Localized id='bc-risks'>Risks</Localized>
                                </h3>
                                <ul className="space-y-2">
                                    {data.risks.map((r, i) => <li key={r.clientId} className="flex items-center space-x-1">
                                        <AlertCircleIcon className="h-5 w-5 text-red-500" />
                                        <EditableElement
                                            defaultValue={r.name}
                                            onChange={(newValue) => updateDataLocally(d => updateRiskItem(d, r.clientId, newValue))}
                                            delete={() => updateDataLocally(d => deleteRiskItem(d, r.clientId))}
                                        />
                                    </li>)}
                                    <li className="flex items-center space-x-1">
                                        <button onClick={handleAddRiskClick} className="text-red-500 hover:bg-red-500/10" >
                                            <PlusIcon className="h-5 w-5" />
                                            <span className="sr-only">Add Risk</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="space-y-2">
                                <h3 className="text-lg font-medium">
                                    <Localized id='bc-opportunities'>Opportunities</Localized>
                                </h3>
                                <ul className="space-y-2">
                                    {data.opportunities.map((r, i) => <li key={r.clientId} className="flex items-center space-x-1">
                                        <CheckIcon className="h-5 w-5 text-green-500" />
                                        <EditableElement
                                            defaultValue={r.name}
                                            onChange={(newValue) => updateDataLocally(d => updateOpportunityItem(d, r.clientId, newValue))}
                                            delete={() => updateDataLocally(d => deleteOpportunityItem(d, r.clientId))}
                                        />
                                    </li>)}
                                    <li className="flex items-center space-x-2">
                                        <button onClick={handleAddOpportunityClick} className="text-green-500 hover:bg-green-500/10" >
                                            <PlusIcon className="h-5 w-5" />
                                            <span className="sr-only">Add Opportunity</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FormControlLabel
                            control={<Checkbox checked={data.objectiveCompliancy} onChange={(e) => updateDataLocally(d => d.objectiveCompliancy = e.target.checked)} />}
                            label={<Localized id='bc-company-compliance'>This business case is compliant with our company's strategic objectives</Localized>}
                        />
                    </div>
                    <div className="space-y-2">
                        <AppBlobsProvider<void> guid={persisted ? data.guid : null} component={Attachments} args={undefined} />
                    </div>
                </If>
            </div>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <div className="flex justify-end">
                    {unsaved && <Alert variant="filled" severity="warning" sx={{ mr: 2 }}>
                        <Localized id='unsaved-data'>There are unsaved changes</Localized>
                    </Alert>}
                    <Button variant="contained" onClick={() => navigate(-1)}><Localized id='back'>Wróć</Localized></Button>
                    <LoadingButton startIcon={<SaveIcon />} loading={saving} variant="contained" type="submit" onClick={handleSaveClick}>
                        <Localized id='save'>Save</Localized>
                    </LoadingButton>
                    {persisted && 
                    <LoadingButton loading={saving} sx={{ ml: 2 }} variant='contained' 
                    onClick={handleOpenPresentationClick} startIcon={<PictureAsPdfIcon />}>
                        <Localized id='bc-presentation'>Presentation</Localized>
                    </LoadingButton>}
                    {persisted && !unsaved && data.projectId === null && data.status === "APPROVED" && <LoadingButton loading={saving} sx={{ ml: 2 }} variant='contained' onClick={handleStartProjectClick} startIcon={<PlayArrowIcon />}>
                        <Localized id='bc-start-project'>Start project</Localized>
                    </LoadingButton>}
                    {persisted && <LoadingButton onClick={del} startIcon={<DeleteIcon />} color='error' sx={{ ml: 2 }} variant='contained' loading={saving}><Localized id='delete'>Usuń</Localized></LoadingButton>}
                </div>
            </AppBar>
            <Dialog open={projectsDialogOpen} onClose={() => setProjectsDialogOpen(false)} fullWidth maxWidth='lg'>
                <DialogTitle>
                    <Localized id="projects">Projekty</Localized>
                </DialogTitle>
                <DialogContent>
                    <ProjectsBrowser gridRef={projectsGridRef} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmClick}>
                        <Localized id='ok'>OK</Localized>
                    </Button>
                    <Button onClick={() => setProjectsDialogOpen(false)}>
                        <Localized id='cancel'>Anuluj</Localized>
                    </Button>
                </DialogActions>
            </Dialog>

            {isPrintDialogVisible &&
                <BusinessCasePrinter
                    businessCaseId={data.id}
                    language={""}
                    mode={"download"}
                    isDialogOpen={true}
                    closeDialog={handleCloseDialog}
                />
            }
        </section>
    )
}

const Attachments = (props: BlobsProps) => {
    const { blobs, upload, uploading, guid } = props

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])
    const handleInputFileChange = useCallback(async () => {
        if (fileInputRef.current) {
            const dt = fileInputRef.current.files
            if (dt && dt.length > 0) {
                const files: File[] = []
                for (const file of dt) {
                    files.push(file)
                }
                upload(files)
            }
        }
    }, [fileInputRef])

    return <>
        <Typography variant="caption" component="div">
            <Localized id='attachements'>Attachments</Localized>
        </Typography>
        <div className="border rounded-lg p-4 space-y-2">
            {blobs.map(blob => <div key={blob.blobId} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <FileIcon className="h-5 w-5 text-gray-500" />
                    <span>{blob.fileMeta.fileName}</span>
                </div>
                <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${guid}/${blob.blobId}`}>
                    <IconButton><DownloadIcon /></IconButton>
                </a>
            </div>)}
        </div>
        <div className="flex items-center space-x-2">
            <form ref={formRef}>
                <input
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    value=''
                    onChange={handleInputFileChange}
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                />
            </form>
            <LoadingButton loading={uploading} onClick={handleSelectFilesClick} variant="contained" startIcon={<CloudUploadIcon />}>
                <Localized id='upload'>Upload</Localized>
                <VisuallyHiddenInput type="file" />
            </LoadingButton>
        </div>
    </>
}

function AlertCircleIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" x2="12" y1="8" y2="12" />
            <line x1="12" x2="12.01" y1="16" y2="16" />
        </svg>
    )
}


function CheckIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polyline points="20 6 9 17 4 12" />
        </svg>
    )
}

function FileIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
            <polyline points="14 2 14 8 20 8" />
        </svg>
    )
}


function PlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M5 12h14" />
            <path d="M12 5v14" />
        </svg>
    )
}

export interface EditableElementProps {
    defaultValue: string
    onChange: (v: string) => void
    delete: () => void
}
export const EditableElement = (props: EditableElementProps) => {
    const [value] = useState(props.defaultValue)
    const spanEl = useRef<HTMLSpanElement | null>(null)

    useEffect(() => {
        if (spanEl && spanEl.current) {
            spanEl.current.textContent = value
        }
    }, [])

    //TODO: maybe onBlur event is better option - make some assesment
    return <span
        className="px-2 rounded"
        ref={spanEl}
        contentEditable={true}
        onInput={(e) => {
            props.onChange(e.currentTarget.textContent ?? '')
        }}
        onKeyDown={(e) => {
            if (!e.currentTarget.textContent && e.key === 'Backspace') {
                props.delete()
            }
        }}></span>
}

const updateRiskItem: (data: BusinessCaseWithDetails, id: string, name: string) => void = (data, id, name) => {
    const risk = data.risks.find(r => r.clientId === id)
    if (risk) {
        risk.name = name
    }
}
const updateOpportunityItem: (data: BusinessCaseWithDetails, id: string, name: string) => void = (data, id, name) => {
    const opportunity = data.opportunities.find(r => r.clientId === id)
    if (opportunity) {
        opportunity.name = name
    }
}
const deleteRiskItem: (data: BusinessCaseWithDetails, id: string) => void = (data, id) => {
    const index = data.risks.findIndex(r => r.clientId === id)
    if (index !== -1) {
        data.risks.splice(index, 1)
    }
}
const deleteOpportunityItem: (data: BusinessCaseWithDetails, id: string) => void = (data, id) => {
    const index = data.opportunities.findIndex(r => r.clientId === id)
    if (index !== -1) {
        data.opportunities.splice(index, 1)
    }
}
