import { Box, Divider, FormHelperText, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Editor, EditorConsumer, EditorContent, EditorContext, EditorProvider, useCurrentEditor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import './RichTextEditor.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { useCallback, useEffect, useState } from 'react';
import Mention from '@tiptap/extension-mention';
import { mkSuggestion } from './suggestion';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import { Localized } from '@fluent/react';
import TableRowsIcon from '@mui/icons-material/TableRows';
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import ImageIcon from '@mui/icons-material/Image'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'

export interface RichTextEditorProps {
    value         : string
    onValueChange : (newValue : string) => void
    label         : React.ReactNode | null
    setImage?     : (resolveImageUrl: (url: string) => void, reject: (reason?: any) => void) => void
}

const BoxWithBorder = styled('div')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 2),
}));

interface MenuBarProps {
    setImage?     : (resolveImageUrl: (url: string) => void, reject: (reason?: any) => void) => void
}
const MenuBar = (props: MenuBarProps) => {
    const { setImage } = props
    const { editor } = useCurrentEditor()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleTableClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleTableClose = () => {
        setAnchorEl(null)
    }
    const addImage = useCallback(() => {
        if (setImage && editor) {
            new Promise<string>(setImage).then(imgUrl => {
                editor.chain().focus().setImage({ src: imgUrl }).run()
            })
        }
    }, [editor, setImage])

    if (!editor) {
        return null
    }

    const handleAddNewTableClick = () => {
        editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
        setAnchorEl(null)
    }
    const handleDeleteTableClick = () => {
        editor.chain().focus().deleteTable().run()
        setAnchorEl(null)
    }
    const handleAddColumnAfterClick = () => {
        editor.chain().focus().addColumnAfter().run()
        setAnchorEl(null)
    }
    const handleAddColumnBeforeClick = () => {
        editor.chain().focus().addColumnBefore().run()
        setAnchorEl(null)
    }
    const handleAddRowAfterClick = () => {
        editor.chain().focus().addRowAfter().run()
        setAnchorEl(null)
    }
    const handleAddRowBeforeClick = () => {
        editor.chain().focus().addRowBefore().run()
        setAnchorEl(null)
    }
    const handleDeleteColumnClick = () => {
        editor.chain().focus().deleteColumn().run()
        setAnchorEl(null)
    }
    const handleDeleteRowClick = () => {
        editor.chain().focus().deleteRow().run()
        setAnchorEl(null)
    }

    const editable = editor.isEditable

    return <Box sx={{ mb: 1 }}>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editable || !editor.can().chain().focus().toggleBold().run()}
            color={editor.isActive('bold') ? 'secondary' : 'primary'}
        ><FormatBoldIcon /></IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editable || !editor.can().chain().focus().toggleItalic().run()}
            color={editor.isActive('italic') ? 'secondary' : 'primary'}
        ><FormatItalicIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            color={editor.isActive('bulletList') ? 'secondary' : 'primary'}
        ><FormatListBulletedIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            color={editor.isActive('orderedList') ? 'secondary' : 'primary'}
        ><FormatListNumberedIcon /></IconButton>
        <IconButton 
            size="small"
            disabled={!editable}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            color={editor.isActive('blockquote') ? 'secondary' : 'primary'}
        ><FormatQuoteIcon /></IconButton>
        {setImage !== undefined && <IconButton
            size='small'
            disabled={!editable}
            onClick={addImage}
        ><ImageIcon /></IconButton>}
        <IconButton size='small' onClick={handleTableClick}>
            <TableRowsIcon />
        </IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editable || !editor.can().chain().focus().undo().run()}
        ><UndoIcon /></IconButton>
        <IconButton 
            size="small"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editable || !editor.can().chain().focus().redo().run()}
        ><RedoIcon /></IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleTableClose}>
            <MenuItem key='create' onClick={handleAddNewTableClick}><Localized id='table-add'>Add table</Localized></MenuItem>
            <MenuItem key='delete' onClick={handleDeleteTableClick}><Localized id='table-delete'>Delete table</Localized></MenuItem>
            <Divider />
            <MenuItem key='add-col-before' onClick={handleAddColumnBeforeClick}><Localized id ='table-add-col-before'>Add col before</Localized></MenuItem>
            <MenuItem key='add-col-after' onClick={handleAddColumnAfterClick}><Localized id='table-add-col-after'>Add col after</Localized></MenuItem>
            <MenuItem key='delete-col' onClick={handleDeleteColumnClick}><Localized id='table-delete-col'>Delete col</Localized></MenuItem>
            <Divider />
            <MenuItem key='add-row-before' onClick={handleAddRowBeforeClick}><Localized id='table-add-row-before'>Add row before</Localized></MenuItem>
            <MenuItem key='add-row-after' onClick={handleAddRowAfterClick}><Localized id='table-add-row-after'>Add row after</Localized></MenuItem>
            <MenuItem key='delete-row' onClick={handleDeleteRowClick}><Localized id='table-delete-row'>Delete row</Localized></MenuItem>
        </Menu>
    </Box>
}

export function mkTipTapExtWithMention(persons: string[]) {
    return [...tiptapExtensions, Mention.configure({
        suggestion: mkSuggestion(persons),
        HTMLAttributes: {
            class: 'font-semibold text-blue-600',
        },
    })]
}

export const tiptapExtensions = [
    StarterKit.configure({
        // bulletList: {
        //     keepMarks: true,
        //     keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
        // orderedList: {
        //     keepMarks: true,
        //     keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        // },
        heading: {
            levels: [1, 2, 3],
        },
    }),
    TaskList,
    TaskItem.configure({
        nested: true,
    }),
    Image,
    Table,
    TableRow,
    TableHeader,
    TableCell,
]

export const RichTextEditor = ({ value, onValueChange, label, setImage }: RichTextEditorProps) => {
    return <Box>
        <FormHelperText>{!label ? 'Nazwa wymagania*' : label}</FormHelperText>
        <BoxWithBorder>
            <EditorProvider onUpdate={e => onValueChange(e.editor.getHTML())} slotBefore={<MenuBar setImage={setImage} />} extensions={tiptapExtensions} content={value}>
                <></>
            </EditorProvider>
        </BoxWithBorder>
    </Box>
}

export interface RichTextContainerProps {
    label         : React.ReactNode | null
    editor        : Editor | null
    setImage?     : (resolveImageUrl: (url: string) => void, reject: (reason?: any) => void) => void
}
export const RichTextContainer = (props: RichTextContainerProps) => {
    const { label, editor, setImage } = props

    if (!editor) return null

    return <Box>
        <FormHelperText>{!label ? 'Nazwa wymagania*' : label}</FormHelperText>
        <BoxWithBorder>
            <EditorContext.Provider value={{editor}}>
                <MenuBar setImage={setImage} />
                <EditorConsumer>
                    {({ editor: currentEditor }) => (
                        <EditorContent editor={currentEditor} />
                    )}
                </EditorConsumer>
            </EditorContext.Provider>
        </BoxWithBorder>
    </Box>
}

export const RichTextEditorExample = () => {
    const editor = useEditor({
        extensions: tiptapExtensions,
        content: '<p>Initial content</p>',   // initial value
        onUpdate: (e) => {                   // callback to get the updated value
            console.log(e.editor.getHTML())
        },
    })

    useEffect(() => {
        if (editor) {
            editor.commands.setContent('<p>Initial content</p>')
        }
    }, [editor])

    return <RichTextContainer label="Example" editor={editor} />
}

export const useJustText = (props: { defaultValue: string, onChange: () => void, editable? : boolean | undefined }) : Editor | null => {
    const editor = useEditor({
        extensions: [Document, Paragraph, Text],
        content: props.defaultValue,
        editable: props.editable,
        onUpdate: e => props.onChange(),
    })
    return editor
}

export const JustText = ({ editor }: { editor: Editor | null }) => {
    if (!editor) {
        return null
    }
    return <EditorContent editor={editor} />
}
