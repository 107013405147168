import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FlexDiv } from '../../../app/Utils';
import { deleteMapping, loadMailMappings, MailMapping, selectAllMailMappings, selectMailMappings } from './mailMappingsSlice'
import { showError, showSuccess } from '../../notifications/notificationsSlice'
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, IconButton, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { MailMappingEditDialog } from './MailMappingEditDialog'
import { LoadingButton } from '@mui/lab'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { LoadingContainer } from '../../../app/LoadingContainer'
import { selectSettings } from '../../settings/settingsSlice'
import { enUS, plPL } from '@mui/x-data-grid/locales'
import { QuickSearchToolbar } from '../../../app/QuickSearchToolbar'
import { selectUserByName } from '../../users/usersSlice'
import { RootState } from '../../../app/store'
import { selectCompanyById } from '../../company/companiesSlice'
import SyncIcon from '@mui/icons-material/Sync';

type DialogState = 
    | { type: "none" }
    | { type: "createUpdate", mappingId: number | undefined, options: string[] }

    
const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])
    const onError = useCallback(() => {
        setter({ type: "none" })
        dispatch(showError("error"))
    }, [setter, dispatch])

    switch (state.type) {
        case "none":
            return null

        case "createUpdate":
            return <MailMappingEditDialog
                id={state.mappingId} 
                options={state.options}
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
    />
    }
}

const GetUserCompanyName = ({ userName }: { userName: string}) => {
    const user = useAppSelector((state:RootState) => selectUserByName(state, userName));
    const company = useAppSelector((state:RootState) => selectCompanyById(state, user !== undefined ? user.companyId : "0"))

    return <>{ company?.name }</>;
}

export const SystemTestSettings = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const { loaded } = useAppSelector(selectMailMappings)
    const allMailMappings = useAppSelector(selectAllMailMappings)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [filteredMailMappings, setFilteredMailMappings] = useState(allMailMappings);

    const [group, setGroup] = useState();

    const handleGroupChange = (e) => setGroup(e.target.value);

    
    useEffect(() => {
        if (!loaded) {
            setIsLoading(true);
            dispatch(loadMailMappings())
        } else {
            setIsLoading(false);
        }
    }, [dispatch, loaded])

    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })
    const [itemToDelete, setItemToDelete] = useState<number | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)

    const handleDeleteClick = useCallback((id: number) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleDeleteMappingClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                await dispatch(deleteMapping(itemToDelete))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    const handleEditMappingClick = useCallback((mappingId: number, options: string[]) => {
        setDialog({
            type: "createUpdate",
            mappingId,
            options: options
        })
    }, [setDialog])

    const handleRefreshClick = () => {
        if(group === 'all') {
            setFilteredMailMappings(allMailMappings);
        } else {
            setFilteredMailMappings(allMailMappings.filter(f => f.group === group))
        }
    }

    const columns: GridColDef<MailMapping, any, any>[] = [
        {
            field: 'mail',
            headerName: l10n.getString("email-address", null, "Mail oryginalny"),
            width: 300
        },
        {
            field: 'company',
            headerName: l10n.getString("company", null, "Firma"),
            width: 300,
            renderCell: (params) => {
                return <GetUserCompanyName userName={params.row.mail}/>
            }
        },
        {
            field: "redirectToMail",
            headerName: l10n.getString("redirect-to-address", null, "Przekierowanie na maila"),
            flex: 1
        },
        {
            field: "group",
            headerName: l10n.getString("system-test-group", null, "Pula"),
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            width: 250,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleEditMappingClick(params.row.id, allMailMappings.map(m => m.group).filter((group, index, self) => group !== null && self.indexOf(group) === index))} color="secondary" variant="outlined">
                            <Localized id="edit">
                            <span>Edytuj</span>
                        </Localized>
                        </Button>
                        <Button onClick={() => handleDeleteClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                <FormControl>
                    <InputLabel id="select-company-label"><Localized id="company">Firma</Localized></InputLabel>
                    <Select
                        labelId="select-company-label"
                        value={group}
                        onChange={handleGroupChange}
                        label={<Localized id="company">Firma</Localized>}
                        sx={{ width: '200px' }}
                    >
                        <MenuItem value={'all'}>
                            <Localized id="all">All</Localized>
                        </MenuItem>
                        {allMailMappings.map(m => m.group).filter((group, index, self) => group !== null && self.indexOf(group) === index).map((option) =>
                            <MenuItem value={option}>{option}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <IconButton
                    onClick={handleRefreshClick}
                    >
                        <SyncIcon />
                </IconButton>
            </Stack>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 280px)' }}
                localeText={getCustomLocaleText()}
                rows={filteredMailMappings}
                columns={columns}
                slots={{ toolbar: QuickSearchToolbar }}
                initialState={{
                    sorting: { sortModel: [{ field: 'mail', sort: 'asc' }] }
                }}
            />
            <FlexDiv>
                <Fab sx={{
                        marginTop: 2,
                    }} 
                    onClick={() => handleEditMappingClick(0, allMailMappings.map(m => m.group).filter((group, index, self) => group !== null && self.indexOf(group) === index))}
                    color="secondary" 
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
            <Dialog open={dialog.type !== "none"} fullWidth maxWidth="md" keepMounted={false}>
                <DialogContentDispatcher state={dialog} setter={setDialog} />
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={deleting} onClick={handleDeleteMappingClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </>
    );
}