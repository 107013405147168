import { Localized, useLocalization } from "@fluent/react";
import {
    Alert,
    AlertTitle,
    AppBar,
    Badge,
    Button,
    ButtonGroup, Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCallback, useMemo, useState } from "react";
import { If } from "../../app/If";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { Box } from "@mui/system";
import produce from "immer";
import { selectSettings } from '../settings/settingsSlice';
import MenuItem from '@mui/material/MenuItem';
import { ReqCriticalityLabel } from "../urs/ReqCriticalityLabel";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { Check, Clear } from "@mui/icons-material";
import { autoMatchOffer, createUrsOfferComment, deleteUrsOfferComment, rejectOffer, RequirementNote, selectAllOfferRequirements, sendOffer, sendToEvaluation, updateUrsOffer, updateUrsOfferComment, UrsOffer, UrsOfferMessage, UrsOfferRequirement, postUrsOfferRequirementNote, assignResponsibleUser, matchRequirementWithOffer, DiscussionType } from "./ursOfferSlice";
import { NoteLabel } from "./NoteLabel";
import { selectAllSupplierDevices, SupplierDevice } from "../supplierdevices/supplierDeviceSlice";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { selectAllSupplierDeviceDetail, selectSupplierDeviceDetailById } from "../supplierdevices/supplierDeviceDetailsSlice";
import { Comment, CommentKind, Discuss } from "../../app/Discuss";
import { selectLoggedUserId } from "../user/userSlice";
import { parseISODate } from "../../app/Utils";
import { groupBy } from "../../app/arrays";
import { Blobs, selectBlobsByResourceId, uploadBlobs, Uuid } from "../urs/ursAttachementsSlice";
import { EntityState, unwrapResult } from "@reduxjs/toolkit";
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from "react-router-dom";
import { SelectUser } from "../users/SelectUser";
import { FullNameByUserId } from "../users/FullName";
import { OfferFormProps, URSRequirementRowProps } from "./types";
import AttachmentIcon from '@mui/icons-material/Attachment';
import { OfferAttachementsDialog } from "./OfferAttachementsDialog";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate } from "react-router-dom";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { UrsRequestToolbarMenu } from "./utils/UrsRequestToolbarMenu";
import { SupplierUrsOfferHeader } from "./utils/SupplierUrsOfferHeader";

interface OfferGridRow {
    id: AppId
    name: string
    description: string
    requirementId: AppId
}

type MainTabs = "reqs" | "description" | "attachments"


const URSRequirementRow = ({
    requirement,
    onEditClick,
    onApproveClick,
    canEdit,
    onOfferDetailClick,
    onCommentsClick,
    onAssignToClick,
    onAttachementsClick,
    commentsCount,
    client }: URSRequirementRowProps) => {
    const supplierDeviceDetail = useAppSelector(state => selectSupplierDeviceDetailById(state, Number.parseInt(requirement.supplierDeviceDetailId ?? '0')))

    const blobs = useAppSelector(state => selectBlobsByResourceId(state, requirement.guid))
    const attachementsCount = blobs?.blobs?.length ?? 0

    return <TableRow>
        <TableCell> {requirement.numeration} </TableCell>
        <TableCell dangerouslySetInnerHTML={{ __html: requirement.name }}></TableCell>
        <TableCell>{<ReqCriticalityLabel criticality={requirement.criticality ?? ""} />}</TableCell>
        <TableCell>
            <Badge color="info" badgeContent={attachementsCount} invisible={attachementsCount === 0}>
                <Tooltip title={<Localized id="attachements">Załączniki</Localized>}>
                    <IconButton size="small" onClick={() => onAttachementsClick(requirement)}>
                        <AttachmentIcon />
                    </IconButton>
                </Tooltip>
            </Badge>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <NoteLabel note={requirement.note ?? ''} />
        </TableCell>
        <TableCell>{requirement.comment}</TableCell>
        <TableCell>{supplierDeviceDetail ? supplierDeviceDetail.name : "?"}</TableCell>
        <TableCell><FullNameByUserId userId={requirement.responsibleUserId ?? ''}></FullNameByUserId></TableCell>
        <TableCell >
            <ButtonGroup variant="outlined" color="secondary" size="small">
                <If condition={!client && canEdit}>
                    <Tooltip title={<Localized id="urs-offer-action-approve"><span>Approve</span></Localized>}>
                        <IconButton size="small" onClick={() => onApproveClick(requirement)}
                            sx={{
                                color: 'default',
                                '&:hover': {
                                    color: 'success.main',
                                },
                            }}>
                            <DoneAllIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={!client && canEdit}>
                    <Tooltip title={<Localized id="urs-offer-action-rate"><span>Oceń</span></Localized>}>
                        <IconButton size="small" onClick={() => onEditClick(requirement)}>
                            <AssessmentIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={!client && canEdit}>
                    <Tooltip title={<Localized id="urs-offer-supplier-device-detail"><span>Offer</span></Localized>}>
                        <IconButton size="small" onClick={() => onOfferDetailClick(requirement)}>
                            <LocalOfferIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                <If condition={!client && canEdit}>
                    <Tooltip title={<Localized id="urs-offer-add-responsible"><span>Assign to</span></Localized>}>
                        <IconButton size="small" onClick={() => onAssignToClick(requirement)}>
                            <PersonAddIcon />
                        </IconButton>
                    </Tooltip>
                </If>
                {commentsCount > 0 &&
                    <Badge color='secondary' badgeContent={commentsCount} invisible={commentsCount === 0}>
                        <Tooltip title={<Localized id="discussion"><span>Dyskusja</span></Localized>}>
                            <IconButton size="small" onClick={() => onCommentsClick(requirement)}>
                                <CommentIcon />
                            </IconButton>
                        </Tooltip>
                    </Badge>
                }

            </ButtonGroup>
        </TableCell>
    </TableRow>

}

type DialogState
    = "none"
    | "addReqComment"
    | "confirmSendOffer"
    | "confirmRejectOffer"
    | "confirmSendToEvaluate"
    | "offerDetail"
    | "selectDevice"
    | "confirmAutoMatch"
    | "comments"
    | "addResponsible"
    | "attachements"

export const SuppliersOfferForm = ({ entity, mode, comments, blobs }: OfferFormProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { l10n } = useLocalization()
    const [draftEntity, setDraftEntity] = useState(entity)
    const { locale } = useAppSelector(selectSettings);
    const currentUserId = useAppSelector(selectLoggedUserId)

    const [tab, setTab] = useState<MainTabs>("reqs")
    const [dialog, setDialog] = useState<DialogState>("none")
    const [askClient, setAskClient] = useState(false)
    const [actionPending, setActionPending] = useState(false)
    const [discussionType, setDiscussionType] = useState<DiscussionType>('PRIVATE_COMPANY')

    const [showOnlyNonEvaluated, setShowOnlyNonEvaluated] = useState(false)
    const [showOnlyDiscussed, setShowOnlyDiscussed] = useState(false)

    const handleAskClientClick = useCallback(() => { setAskClient(true) }, [])

    const handleReqCommentChange: AppTextFieldHandler = useCallback(e => setReqComment(e.target.value), [])
    const handleReqCommentStatusChange: AppTextFieldHandler = useCallback(e => setReqNote(e.target.value as RequirementNote), [])

    const [requirement, setRequirement] = useState<UrsOfferRequirement | null>(null)
    const [reqNote, setReqNote] = useState<RequirementNote | null>("")
    const [reqComment, setReqComment] = useState("")
    const [offerComment, setOfferComment] = useState("")
    const [internalComments, setInternalComments] = useState<UrsOfferMessage[]>(comments)

    const allSupplierDevices = useAppSelector(state => selectAllSupplierDevices(state).filter(({ structureId }) => structureId === entity.deviceTypeId))
    const [supplierDevice, setSupplierDevice] = useState<SupplierDevice | undefined>(() => {
        if (entity.supplierDeviceId) {
            return allSupplierDevices.find(({ id }) => id === entity.supplierDeviceId)
        } else {
            return undefined
        }
    })

    const supplierDeviceDetails = useAppSelector(state => {
        if (supplierDevice) {
            return selectAllSupplierDeviceDetail(state).filter(({ supplierDeviceId }) => supplierDeviceId.toString() === supplierDevice.id)
        }
        else {
            return []
        }
    })

    const [contextTypeId, setContextTypeId] =
        useState<AppId | undefined>(entity.types.length > 0 ? entity.types[0].id : undefined)
    const contextType = useMemo(() => draftEntity.types.find(x => x.id === contextTypeId), [contextTypeId, draftEntity.types])

    const handleReqsTabChange = useCallback((_, newType) => {
        setContextTypeId(newType)
    }, [])

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])
    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])


    const handleCommentOKButtonClickk = async () => {
        if (requirement) {
            setActionPending(true)
            try {
                await dispatch(postUrsOfferRequirementNote({
                    id: entity.id,
                    requirementId: requirement.id,
                    comment: reqComment,
                    note: reqNote,
                })).unwrap()
                setDraftEntity(
                    produce(draft => {
                        for (const mainGr of draft.types) {
                            for (const req of mainGr.requirements) {
                                if (req.id === requirement.id) {
                                    req.comment = reqComment
                                    req.note = reqNote
                                }
                            }
                            for (const group of mainGr.groups) {
                                for (const req of group.requirements) {
                                    if (req.id === requirement.id) {
                                        req.comment = reqComment
                                        req.note = reqNote
                                    }
                                }
                            }
                        }
                    }))
            } catch (error) {
                console.log(error)
            } finally {
                setActionPending(false)
            }
        }
        setDialog("none")
    }
    const handleMatchClick = useCallback((params: GridRowParams<OfferGridRow>) => {
        dispatch(matchRequirementWithOffer({ offerId: entity.id, requirementId: params.row.requirementId, supplierDeviceDetailId: params.row.id.toString() }))
            .then(unwrapResult)
            .then(result => {
                setDraftEntity(result)
                setDialog("none")
            })
            .catch(() => { dispatch(showError("error")) })
    }, [])
    const deviceDetailCols: GridColDef[] = useMemo(() => [
        {
            field: 'name',
            headerName: l10n.getString("devices-parameter-name", null, "Name"),
            flex: 1,
        },
        {
            field: 'description',
            headerName: l10n.getString("devices-parameter-description", null, "Description"),
            flex: 2
        },
        {
            field: 'actions',
            type: 'actions',
            getActions: (params: GridRowParams) => [
                <Button onClick={() => handleMatchClick(params)}><Localized id="urs-offer-match">Match</Localized></Button>
            ]
        }
    ], [handleMatchClick])
    const handleDialogCancelClick = useCallback(() => {
        setDialog("none")
    }, [])

    const handleDeviceChange = (event: SelectChangeEvent) => {
        const newDevice = allSupplierDevices.find(({ id }) => id === event.target.value)
        if (newDevice) {
            setSupplierDevice(newDevice)
        }
    }
    const handleSelectDeviceClick = useCallback(() => {
        setDialog("selectDevice")
    }, [])

    const handleConfirmDeviceSelectionClick = () => {
        dispatch(updateUrsOffer({
            ...draftEntity,
            supplierDeviceId: supplierDevice?.id ?? "",
        })).then(unwrapResult).then(result => {
            setDraftEntity(result)
            dispatch(showSuccess("saved"))
        }).catch(() => { dispatch(showError("error")) }).finally(() => { setDialog("none") })
    }


    const handleCancelDeviceSelectionClick = useCallback(() => {
        setDialog("none")
    }, [])

    const handlePrintUrsClick = async () => {
        if (draftEntity.id) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/urs-offer/${draftEntity.id}?language=${locale}`, '_blank')
        }
    }

    const handleSendOfferClick = async () => {
        setDialog("confirmSendOffer")
    }

    const handleAutoMatchClick = async () => {
        setDialog("confirmAutoMatch")
    }


    const handleRejectOfferClick = async () => {
        setDialog("confirmRejectOffer")
    }

    const handleSendToEvaluateClick = async () => {
        setDialog("confirmSendToEvaluate")
    }

    const handleOfferCommentChange: AppTextFieldHandler = useCallback(e => setOfferComment(e.target.value), [])

    const handleConfirmSendOfferClick = async () => {
        try {
            const result = await dispatch(sendOffer(draftEntity.id));

            if (sendOffer.fulfilled.match(result)) {
                const updatedEntity = result.payload as UrsOffer;
                setDraftEntity(updatedEntity)
                dispatch(showSuccess("saved"))

            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
    }


    const handleConfirmRejectOfferClick = async () => {

        try {

            const updatedEntity = await dispatch(rejectOffer({ offerId: draftEntity.id, comment: offerComment })).unwrap()
            setDraftEntity(updatedEntity)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")

    }




    const handleConfirmAutoMatchClick = async () => {
        try {
            const updatedEntity = await dispatch(autoMatchOffer(draftEntity.id)).unwrap()
            setDraftEntity(updatedEntity)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")
    }

    const handleConfirmSendToEvaluateClick = async () => {
        try {

            const updatedEntity = await dispatch(sendToEvaluation({ offerId: draftEntity.id, comment: offerComment })).unwrap()
            setDraftEntity(updatedEntity)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setDialog("none")

    }


    const handleEditRequirementClick = useCallback((requirement: UrsOfferRequirement) => {

        const { note, comment } = requirement
        setAskClient(false)
        setRequirement(requirement)
        setReqNote(note)
        setReqComment(comment)
        setDialog("addReqComment")

    }, [])

    const handleApproveClick = useCallback(async (updatedRequirement: UrsOfferRequirement) => {
        setActionPending(true)
        try {
            await dispatch(postUrsOfferRequirementNote({
                id: entity.id,
                requirementId: updatedRequirement.id,
                comment: updatedRequirement.comment,
                note: 'F',
            })).unwrap()
            setDraftEntity(
                produce(draft => {
                    for (const mainGr of draft.types) {
                        for (const req of mainGr.requirements) {
                            if (req.id === updatedRequirement.id) {
                                req.note = 'F'
                            }
                        }
                        for (const group of mainGr.groups) {
                            for (const req of group.requirements) {
                                if (req.id === updatedRequirement.id) {
                                    req.note = 'F'
                                }
                            }
                        }
                    }
                }))
        } catch (error) {
            console.log(error)
        } finally {
            setActionPending(false)
        }
    }, [])

    const handleSelectOfferDetailClick = useCallback((requirement: UrsOfferRequirement) => {
        setRequirement(requirement)
        setDialog("offerDetail")
    }, [])
    const handleCommentsClick = useCallback((requirement: UrsOfferRequirement) => {
        setRequirement(requirement)
        setDialog("comments")
    }, [])
    const handleAssignToClick = useCallback((requirement: UrsOfferRequirement) => {
        setRequirement(requirement)
        setPersonResponsible(requirement.responsibleUserId ?? '')
        setDialog("addResponsible")
    }, [])
    const [personResponsible, setPersonResponsible] = useState<AppId>("")

    const handleAttachementsClick = useCallback((requirement: UrsOfferRequirement) => {
        setRequirement(requirement)
        setDialog('attachements')
    }, [])

    const deviceDetailsRows = useMemo(() => {
        return supplierDeviceDetails.map(({ id, name, description }) => {
            return {
                id,
                name,
                description,
                requirementId: requirement?.id ?? "",
            }
        })
    }, [requirement, supplierDeviceDetails])

    const publicComments = useMemo(() => {
        return internalComments.filter(({ ursRequirementId, discussionType }) => ursRequirementId.toString() === requirement?.id && discussionType === 'INTER_COMPANY').map(({ id, message, author, parentMessageId, userId, companyName, date, guid, type }) => {
            return {
                someId: id,
                content: message,
                author: author ?? "Bez nazwy",
                parentId: parentMessageId,
                authorId: userId,
                companyName: companyName,
                date: parseISODate(date),
                guid,
                kind: type,
            } as Comment
        })
    }, [requirement, internalComments])

    const privateComments = useMemo(() => {
        return internalComments.filter(({ ursRequirementId, discussionType }) => ursRequirementId.toString() === requirement?.id && discussionType === 'PRIVATE_COMPANY').map(({ id, message, author, parentMessageId, userId, companyName, date, guid, type }) => {
            return {
                someId: id,
                content: message,
                author: author ?? "Bez nazwy",
                parentId: parentMessageId,
                authorId: userId,
                companyName: companyName,
                date: parseISODate(date),
                guid,
                kind: type,
            } as Comment
        })
    }, [requirement, internalComments])

    const postComment = async (message: string, parentCommentId: AppId | null, kind: CommentKind, files: File[] | null = null): Promise<Uuid | undefined> => {
        if (requirement !== null) {
            try {
                const newComment = await dispatch(createUrsOfferComment({
                    id: "",
                    ursOfferId: entity.id,
                    ursRequirementId: requirement.id,
                    message,
                    author: "",
                    companyName: "",
                    userId: currentUserId,
                    date: new Date().toISOString(),
                    parentMessageId: parentCommentId,
                    status: "",
                    guid: '',
                    discussionType,
                    type: kind,
                })).unwrap()
                if (files !== null && newComment.guid) {
                    await dispatch(uploadBlobs([newComment.guid, files])).unwrap()
                }
                setInternalComments(produce(draft => {
                    draft.push(newComment)
                }))
                return newComment.guid
            } catch (e) {
                /* handle error */
                console.log(e)
            }
        }
    }

    const updateComment = async (commentId: AppId, message: string) => {
        const comment = internalComments.find(x => x.id === commentId)
        if (requirement !== null && comment) {
            try {
                const newComment = await dispatch(updateUrsOfferComment({
                    ...comment,
                    message,
                })).unwrap()
                setInternalComments(produce(draft => {
                    const i = draft.findIndex(x => x.id === commentId)
                    if (i >= 0) {
                        draft[i] = newComment
                    }
                }))
            } catch (e) {
                /* handle error */
                console.log(e)
            }
        }
    }

    const deleteComment = async (id: AppId) => {
        try {
            await dispatch(deleteUrsOfferComment(id)).unwrap()
            setInternalComments(oldComments => oldComments.filter(comment => comment.id !== id))
        } catch (e) {
            /* handle error */
            console.log(e)
        }
    }

    const handleShowOnlyNonevaluatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyNonEvaluated(event.target.checked);
    }
    const handleShowOnlyDiscussedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowOnlyDiscussed(event.target.checked);
    }

    const editable = (draftEntity.status === "N" || draftEntity.status === "E") && mode !== "preview"

    const groupedComments = groupBy(internalComments, x => x.ursRequirementId ?? x.ursOfferId)

    if (contextTypeId === undefined || contextType === undefined) return <div>Nothing to display</div>

    const isRowVisible: (requirement: UrsOfferRequirement) => boolean = (requirement) => {
        return (showOnlyDiscussed ? groupedComments[requirement.id]?.length > 0 : true)
            && (showOnlyNonEvaluated ? (requirement.note === null || requirement.note === '') : true)
    }

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
            height: 'calc(100vh - 150px)',
        }} component={Paper} maxWidth={false} >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <SupplierUrsOfferHeader ursOffer={draftEntity}></SupplierUrsOfferHeader>
                </Grid>
                {<Grid item xs={6}>
                    {supplierDevice && <Alert severity="info">
                        <AlertTitle>
                            {supplierDevice.name}
                            <If condition={allSupplierDevices.length > 0}>
                                <Button sx={{ marginLeft: 2, }} onClick={handleSelectDeviceClick} variant='outlined' size="small">
                                    <Localized id="change">Zmień</Localized>
                                </Button>
                            </If>
                        </AlertTitle>
                        {supplierDevice.description}
                    </Alert>}
                    {!supplierDevice &&
                        <If condition={allSupplierDevices.length > 0} otherwise={
                            <Alert severity="warning">
                                <AlertTitle>
                                    <Localized id="urs-offer-supplier-no-device">No device</Localized>
                                </AlertTitle>
                                <Localized id="urs-offer-supplier-no-device-long">It seems that we don't have proper device.</Localized>
                                <Button sx={{ marginLeft: "5px" }} component={Link} to="/supplierdevices/create" variant='outlined' size="small">
                                    <Localized id="add">Dodaj</Localized>
                                </Button>
                            </Alert>
                        }><Alert severity="info">
                                <AlertTitle>
                                    <Localized id="urs-offer-supplier-choose-device">Select proper device</Localized>
                                </AlertTitle>
                                <Button sx={{ marginLeft: 2, }} onClick={handleSelectDeviceClick} variant='outlined' size="small">
                                    <Localized id="choose">Wybierz</Localized>
                                </Button>
                            </Alert>
                        </If>
                    }
                </Grid>}
                <Grid item xs={6}>
                    <div className='flex flex-row-reverse'>
                        <FormGroup row>
                            <FormControlLabel control={
                                <Switch checked={showOnlyNonEvaluated}
                                    onChange={handleShowOnlyNonevaluatedChange} />}
                                label={<Localized id="urs-offer-show-only-not-evaluated">Pokaż tylko nieocenione</Localized>}
                            />
                            <FormControlLabel control={
                                <Switch
                                    checked={showOnlyDiscussed}
                                    onChange={handleShowOnlyDiscussedChange} />}
                                label={<Localized id="urs-offer-show-only-discussed">Pokaż tylko dyskutowane</Localized>}
                            />
                        </FormGroup>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleMainTabChange}>
                            <Tab
                                key={0}
                                value="reqs"
                                label={<Localized id="urs-requirements">Lista wymagań</Localized>} />
                            <Tab
                                key={1}
                                value="description"
                                label={<Localized id="urs-purpose-and-description">Cel i opis</Localized>} />
                            <Tab
                                key={2}
                                value="attachments"
                                label={<Localized id="urs-attachements">Załączniki</Localized>} />
                        </TabList>
                        <TabPanel value="reqs">
                            <Box sx={{
                                display: 'flex',
                                flexGrow: 1,
                            }}>
                                <Tabs
                                    orientation="vertical"
                                    variant="standard"
                                    value={contextTypeId}
                                    onChange={handleReqsTabChange}
                                    sx={{
                                        borderRight: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    {Array.from(draftEntity.types, t => <Tab
                                        key={t.id}
                                        label={t.code}
                                        value={t.id}
                                    />)}
                                </Tabs>
                                <Stack sx={{
                                    paddingLeft: 4,
                                    width: '100%',
                                }} direction="column" spacing={2}>
                                    <Typography variant="subtitle2">
                                        <If condition={contextTypeId !== undefined}
                                            otherwise={<></>}
                                        >{`${contextType.numeration} ${contextType.code} ${contextType.name}`}</If>
                                    </Typography>
                                    <TableContainer sx={{ maxHeight: 'calc(100vh - 600px)' }} component={Paper}> <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ minWidth: 100, width: 100, maxWidth: 100 }}>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-item-lp">Lp</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-item-name">Nazwa</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-item-criticality">Krytyczność</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-attachements">Załączniki</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ minWidth: 100, width: 100, maxWidth: 100, textAlign: "center" }}>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-offer-note">Ocena</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ minWidth: 200 }}>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-offer-comment">Komentarz</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-offer-detail">Offer detail</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography component="span" variant="subtitle2" color="textSecondary">
                                                        <Localized id="urs-offer-assigned-to">Assigned to</Localized>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{ justifyContent: 'flex-end' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            {contextType.requirements.map(requirement => !isRowVisible(requirement) ? null : <URSRequirementRow
                                                key={requirement.id}
                                                requirement={requirement}
                                                onEditClick={handleEditRequirementClick}
                                                onApproveClick={handleApproveClick}
                                                offerStatus={draftEntity.status}
                                                canEdit={editable}
                                                onOfferDetailClick={handleSelectOfferDetailClick}
                                                onCommentsClick={handleCommentsClick}
                                                onAssignToClick={handleAssignToClick}
                                                onAttachementsClick={handleAttachementsClick}
                                                commentsCount={groupedComments[requirement.id]?.length ?? 0}
                                                client={mode === "preview"}
                                            />)}
                                            {contextType.groups.map(group =>
                                                <>
                                                    <TableRow sx={{ bgcolor: '#f1f1f1' }}>
                                                        <TableCell colSpan={9}>{`${group.numeration} ${group.name}`}</TableCell>
                                                    </TableRow>
                                                    {group.requirements.map(
                                                        requirement => !isRowVisible(requirement) ? null : <URSRequirementRow
                                                            key={requirement.id}
                                                            requirement={requirement}
                                                            onEditClick={handleEditRequirementClick}
                                                            onApproveClick={handleApproveClick}
                                                            offerStatus={draftEntity.status}
                                                            canEdit={editable}
                                                            onOfferDetailClick={handleSelectOfferDetailClick}
                                                            onCommentsClick={handleCommentsClick}
                                                            onAssignToClick={handleAssignToClick}
                                                            onAttachementsClick={handleAttachementsClick}
                                                            commentsCount={groupedComments[requirement.id]?.length ?? 0}
                                                            client={mode === "preview"} />)
                                                    }
                                                </>
                                            )}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </Stack>
                            </Box>
                        </TabPanel>
                        <TabPanel value="description" sx={{ height: 'calc(100vh - 450px)', overflowY: 'auto' }}>
                            <Stack direction="column" spacing={4} >
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-title"><span>Tytuł dokumentu</span></Localized>
                                    </Typography>
                                    <div>{draftEntity.title}</div>
                                </Box>
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-purpose"><span>Cel</span></Localized>
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: draftEntity.purpose }} />
                                </Box>
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-description"><span>Opis</span></Localized>
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: draftEntity.description }} />
                                </Box>
                                <Box>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        <Localized id="urs-area"><span>Obszar działania</span></Localized>
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: draftEntity.area }} />
                                </Box>
                            </Stack>
                        </TabPanel>
                        <TabPanel value="attachments">
                            <AttachmentsTable blobs={blobs} requirements={entity.ursOfferRequirements} />
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Grid item xs={12}>

                </Grid>
            </Grid>
            <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <div className="flex justify-end">
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <ButtonGroup variant="contained" color="secondary">
                            <Button color="error" onClick={() => handleRejectOfferClick()} variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                                <Localized id="urs-offer-action-reject">
                                    <span>Zrezygnuj</span>
                                </Localized>
                            </Button>
                            <Button color="primary" onClick={() => handleSendToEvaluateClick()} variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                                <Localized id="urs-offer-action-send-to-evaluation">
                                    <span>Wyślij do oceny</span>
                                </Localized>
                            </Button>

                            <Button onClick={() => handleSendOfferClick()} color="secondary" variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                                <Localized id="urs-offer-action-send-offer">
                                    <span>Złóż ofertę</span>
                                </Localized>
                            </Button>
                            <Button onClick={() => handleAutoMatchClick()} color="secondary" variant="outlined" style={{ display: editable ? "inline" : "none" }}>
                                <Localized id="">
                                    <span>Auto - matching</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <UrsRequestToolbarMenu usrRequestId={draftEntity.id} usrId={draftEntity.ursId} />

                    <Button variant='contained' color="primary" size="small" onClick={handleBackButtonClick}>
                        <Localized id='back'>Wróć</Localized>
                    </Button>
                </div>
            </AppBar>
            <Dialog open={dialog === "addReqComment"} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Localized id="urs-offer-evaluation-title">
                        <span>Wprowadź ocenę wymagania</span>
                    </Localized>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2"
                                dangerouslySetInnerHTML={{ __html: requirement?.name ?? '' }} >
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={reqNote}
                                fullWidth
                                select
                                label={<Localized id="urs-offer-req-opinion"><span>Ocena</span></Localized>}
                                onChange={handleReqCommentStatusChange}
                            >
                                <MenuItem value={"F"}><Check fontSize="small" color='success' /> <Localized id="urs-offer-req-f"><span> Spełnia</span></Localized></MenuItem>
                                <MenuItem value={"U"}><Clear fontSize="small" color='error' /><Localized id="urs-offer-req-u"><span> Nie spełnia</span></Localized></MenuItem>
                                <MenuItem value={"D"}><QuestionMarkIcon fontSize="small" color='warning' /><Localized id="urs-offer-req-d"><span> Do oceny</span></Localized></MenuItem>

                            </TextField>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                minRows={4}
                                inputProps={{ maxLength: 1000 }}
                                value={reqComment}
                                onChange={handleReqCommentChange}
                                label={<Localized id="urs-offer-req-comment"><span>Komentarz</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                size="small"
                                color='primary'
                                exclusive
                                value={discussionType}
                                onChange={(_, newType) => { setDiscussionType(newType) }}
                            >
                                <Badge badgeContent={privateComments.length} invisible={privateComments.length === 0} color='secondary'>
                                    <ToggleButton value='PRIVATE_COMPANY'>
                                        Discussion with team
                                    </ToggleButton>
                                </Badge>
                                <Badge badgeContent={publicComments.length} invisible={publicComments.length === 0} color='secondary'>
                                    <ToggleButton value='INTER_COMPANY'>
                                        Discussion with client
                                    </ToggleButton>
                                </Badge>
                            </ToggleButtonGroup>
                            {discussionType === 'PRIVATE_COMPANY' && <Discuss
                                allComments={privateComments}
                                postComment={postComment}
                                updateComment={updateComment}
                                deleteComment={deleteComment}
                                currentUserId={currentUserId}
                                showTitle={true}
                                readOnly={false}
                            />}
                            {discussionType === 'INTER_COMPANY' && <Discuss
                                allComments={publicComments}
                                postComment={postComment}
                                updateComment={updateComment}
                                deleteComment={deleteComment}
                                currentUserId={currentUserId}
                                showTitle={true}
                                readOnly={false}
                            />}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={actionPending} onClick={handleCommentOKButtonClickk}>
                        <Localized id="ok">
                            <span>Ok</span>
                        </Localized>
                    </LoadingButton>
                    <LoadingButton loading={actionPending} onClick={handleDialogCancelClick}>
                        <Localized id="cancel">
                            <span>Cancel</span>
                        </Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "confirmSendToEvaluate"}>
                <DialogContent>
                    <DialogTitle>
                        <Localized id="urs-offer-action-send-to-evaluation-question">
                            <span>Czy wysłać oferte do oceny klienta?</span>
                        </Localized>
                    </DialogTitle>
                    <Alert severity="info">
                        <Localized id="urs-offer-action-send-to-evaluation-question-descripton">
                            <span>Evaluation</span>
                        </Localized>
                    </Alert>
                    <TextField
                        multiline
                        rows={5}
                        onChange={handleOfferCommentChange}
                        value={offerComment}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ marginTop: "20px", width: "100%" }}
                        label={<Localized id="urs-offer-comment"><span>Komentarz</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSendToEvaluateClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "confirmAutoMatch"}>
                <DialogContent>
                    <DialogTitle>
                        <Localized id="urs-offer-matching-question">
                            <span>Czy chcesz aby system zaproponował powiązania między wymaganiami o ofertą?</span>
                        </Localized>
                    </DialogTitle>
                    <Alert severity="info">
                        <Localized id="urs-offer-matching-question-description">
                            <span>Auto matching</span>
                        </Localized>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmAutoMatchClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "confirmSendOffer"}>
                <DialogTitle>
                    <Localized id="urs-offer-action-send-offer-question">
                        <span>Czy napewno zakończyć komentowanie</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Alert severity="info">
                            <Localized id="urs-offer-action-send-offer-question-description">
                                <span>Zakończenie komentowania?</span>
                            </Localized>
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSendOfferClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "confirmRejectOffer"}>
                <DialogContent>
                    <DialogTitle>
                        <Localized id="urs-offer-action-resign-question">
                            <span>Czy napewno zrezygnować ze składania oferty?</span>
                        </Localized>
                    </DialogTitle>
                    <Alert severity="info">
                        <Localized id="urs-offer-action-resign-question-description">
                            <span>Czy napewno zrezygnować ze składania oferty?</span>
                        </Localized>
                    </Alert>
                    <TextField
                        multiline
                        rows={5}
                        onChange={handleOfferCommentChange}
                        value={offerComment}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ marginTop: "20px", width: "100%" }}
                        label={<Localized id="urs-offer-comment"><span>Komentarz</span></Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmRejectOfferClick}>
                        <Localized id="resign"><span>Resign</span></Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === "offerDetail"} fullWidth maxWidth="xl">
                <DialogTitle>
                    <Localized id="urs-offer-device-detail-browser">
                        Device details browser
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{
                        height: 400,
                        width: '100%',
                    }}>
                        <DataGrid rows={deviceDetailsRows} columns={deviceDetailCols} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel"><span>Cancel</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialog === 'selectDevice'} fullWidth maxWidth="md">
                <DialogTitle>
                    <Localized id="urs-offer-supplier-choose-device">Select device</Localized>
                </DialogTitle>
                <DialogContent>
                    <FormControl sx={{ marginTop: 2 }} fullWidth>
                        <InputLabel id="device-type-name"><Localized id='device-type-name'>Device</Localized></InputLabel>
                        <Select
                            value={supplierDevice?.id ?? ""}
                            label={<Localized id='device-type-name'>Device</Localized>}
                            onChange={handleDeviceChange}
                        >
                            {allSupplierDevices.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDeviceSelectionClick}>
                        <Localized id="save">Save</Localized>
                    </Button>
                    <Button onClick={handleCancelDeviceSelectionClick}>
                        <Localized id="cancel">Cancel</Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === 'comments'} fullWidth maxWidth='md'>
                <DialogTitle>
                    <Localized id="comments">Komentarze</Localized>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <Typography variant="subtitle2"
                            dangerouslySetInnerHTML={{ __html: requirement?.name ?? '' }} >
                        </Typography>
                        <If condition={requirement !== null && requirement.comment !== ""}>
                            <Alert variant='outlined' severity="info">{requirement?.comment ?? ""}</Alert>
                        </If>
                        <ToggleButtonGroup
                            size="small"
                            color='primary'
                            exclusive
                            value={discussionType}
                            onChange={(_, newType) => { setDiscussionType(newType) }}
                        >
                            <Badge badgeContent={privateComments.length} invisible={privateComments.length === 0} color='secondary'>
                                <ToggleButton value='PRIVATE_COMPANY'>
                                    Discussion with team
                                </ToggleButton>
                            </Badge>
                            <Badge badgeContent={publicComments.length} invisible={publicComments.length === 0} color='secondary'>
                                <ToggleButton value='INTER_COMPANY'>
                                    Discussion with client
                                </ToggleButton>
                            </Badge>
                        </ToggleButtonGroup>
                        {discussionType === 'INTER_COMPANY' && <Discuss
                            allComments={publicComments}
                            postComment={postComment}
                            updateComment={updateComment}
                            deleteComment={deleteComment}
                            currentUserId={currentUserId}
                            readOnly={false}
                        />}
                        {discussionType === 'PRIVATE_COMPANY' && <Discuss
                            allComments={privateComments}
                            postComment={postComment}
                            updateComment={updateComment}
                            deleteComment={deleteComment}
                            currentUserId={currentUserId}
                            readOnly={false}
                        />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogCancelClick}><Localized id="close">Zamknij</Localized></Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog === 'addResponsible'} fullWidth maxWidth='md'>
                <DialogTitle>
                    <Localized id='urs-offer-add-responsible'>Assign requirement to another person for assessment</Localized>
                </DialogTitle>
                <DialogContent>
                    <SelectUser initialUserId={personResponsible} onUserIdChange={setPersonResponsible} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        try {
                            const offer = await dispatch(assignResponsibleUser({
                                offerId: entity.id,
                                requirementId: requirement?.id ?? "",
                                userId: personResponsible,
                            })).unwrap()
                            setDraftEntity(offer)
                            setDialog('none')
                        } catch (e) {
                            console.log(e)
                            dispatch(showError('error'))
                        }
                    }}>
                        <Localized id='ok'>OK</Localized>
                    </Button>
                    <Button onClick={() => { setDialog('none') }}>
                        <Localized id='cancel'>Cancel</Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {dialog === 'attachements' && <OfferAttachementsDialog

                onClose={() => { setDialog('none') }}
                requirement={requirement}
                canUpload={false}
                canDelete={false}
            />
            }
        </Container>
    );
}

const AttachmentsTable = ({ blobs, requirements }: { blobs: Blobs[], requirements: EntityState<UrsOfferRequirement> }) => {
    const [heads, ...items] = blobs ?? []
    const headBlobs = heads?.blobs ?? []
    const allRequirements = selectAllOfferRequirements(requirements)
    return <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{ width: '50%' }}></TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {headBlobs.map(blob => {
                    return <TableRow key={blob.blobId}>
                        <TableCell colSpan={2}>
                            <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${heads.resourceId}/${blob.blobId}`}>
                                <IconButton><DownloadIcon /></IconButton>
                            </a>&nbsp;{blob.fileMeta.fileName}
                        </TableCell>
                    </TableRow>
                })}
                {items.map(({ resourceId, blobs }) => {
                    if (blobs === null || blobs.length === 0) {
                        return null
                    } else {
                        const requirement = allRequirements.find(x => x.guid === resourceId)
                        const [blob0, ...rest] = blobs
                        return [blob0].map(blob => {
                            return <TableRow key={blob.blobId}>
                                <TableCell>
                                    <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blob.blobId}`}>
                                        <IconButton><DownloadIcon /></IconButton>
                                    </a>&nbsp;{blob.fileMeta.fileName}
                                </TableCell>
                                <TableCell rowSpan={blobs.length}>{requirement?.name ?? ""}</TableCell>
                            </TableRow>
                        }).concat(rest.map(blob => {
                            return <TableRow key={blob.blobId}>
                                <TableCell>
                                    <a target="_blank" href={`${process.env.REACT_APP_BLOB_API}/blobs/${resourceId}/${blob.blobId}`}>
                                        <IconButton><DownloadIcon /></IconButton>
                                    </a>&nbsp;{blob.fileMeta.fileName}
                                </TableCell>
                            </TableRow>
                        }))
                    }
                })}
            </TableBody>
        </Table>
    </TableContainer>
}
