import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { Localized } from '@fluent/react';
import { AppDispatch } from '../../app/store';
import { downloadBCReport } from './businessCaseSlice';
import { showError } from '../notifications/notificationsSlice';

export type PrinterMode = 'download' | 'open'


interface BusinessCasePrinterProps {
    businessCaseId: string;
    language: string;
    mode: PrinterMode;
    isDialogOpen: boolean;
    closeDialog: () => void;
}

export const BusinessCasePrinter = ({ businessCaseId, language,mode, isDialogOpen,closeDialog  }: BusinessCasePrinterProps) => {
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const dispatch: AppDispatch = useDispatch();

    const handlePrintDocument = async () => {
        setIsGenerating(true);

        try {
            const response = await dispatch(downloadBCReport({ businessCaseId })).unwrap();

            if (mode === 'open') {
                window.open(response.blobUrl);
            } else if (mode === 'download') {
                const link = document.createElement('a');
                link.href = response.blobUrl;
                link.setAttribute('download', response.fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (error) {
            dispatch(showError("error"));
        } finally { 
            setIsGenerating(false);
            closeDialog();
        }
    };

    useEffect(() => {
        if (isDialogOpen) {
            handlePrintDocument();
        }
    }, [isDialogOpen]);

    return (
        <Dialog open={isDialogOpen}>
            <DialogTitle>
                <Localized id="report-generating">
                    <span>Generating Report</span>
                </Localized>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <CircularProgress />
                    <Localized id="report-generating-description">
                        <span>Your report is being generated. Please wait...</span>
                    </Localized>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} disabled={isGenerating}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </Button>
            </DialogActions>
        </Dialog>
    );
};