import { useEffect } from 'react'
import { Devices } from './features/device/Devices'
import { DeviceForm } from './features/device/DeviceForm'
import { Login } from './Login'
import { Logout } from './Logout'
import { DeviceTypes } from './features/device/DeviceTypes'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { loadTokenFromStorage } from './features/user/userSlice'
import { Settings } from './features/settings/Settings'
import { Companies } from './features/company/Companies'
import { Users } from './features/users/Users'
import { Notifications } from './features/notifications/Notifications'
import { AppContainer } from './app/AppContainer'
import { CreateUser } from './features/users/CreateUser'
import { CompanyForm } from './features/company/CompanyForm'
import { Validations } from './features/validations/Validations'
import { CreateDeviceType } from './features/device/CreateDeviceType'
import { EditDeviceType } from './features/device/EditDeviceType'
import { Process } from './features/process/Process'
import { CreateValidation } from './features/validations/CreateValidation'
import { CreateProcess } from './features/process/CreateProcess'
import { ProcessDashboard2 } from './features/process/ProcessDashboard2'
import { ProcessStage } from './features/process/ProcessStage'
import { MyTasks } from './features/tasks/MyTasks'
import { TestCreate, TestEdit } from './features/process/TestEdit'
import { TestResult } from './features/process/TestResult'
import { UrsList } from './features/urs/UrsList'
import { RequirementsDictList } from './features/requirements/RequirementsDictList'
import { UrsTemplatesList } from './features/urstemplates/UrsTemplatesList'
import { UrsTemplateForm } from './features/urstemplates/UrsTemplateForm'
import { RequirementsTypesList } from './features/requirements/RequirementsTypesList'
import { RequirementsTypesForm } from './features/requirements/RequirementsTypesForm'
import { CreateURS } from './features/urs/CreateURS'
import { EditURS } from './features/urs/EditURS'
import { EditRiskAnalysis } from './features/riskAnalysis/EditRiskAnalysis'
import { Areas } from './features/areas/Areas'
import { UrsOfferList } from './features/ursOffer/UrsOfferList'
import { UrsOfferForm } from './features/ursOffer/UrsOfferForm'
import { StructureTabs } from './features/device/Structure'
import { Modules } from './features/modules/Modules'
import { ModuleAccess } from './features/modules/ModuleAccess'
import { CompanyUsers } from './features/users/CompanyUsers'
import { CreateCompanyUser } from './features/users/CreateCompanyUser'
import { Authenticate } from './features/user/Authenticate'
import { EditValidation } from './features/validations/EditValidation'
import { ViewValidation } from './features/validations/ViewValidation'
import { AiSettings } from './features/ai/AiSettings'
import { Suppliers } from './features/suppliers/Suppliers'
import { SupplierForm } from './features/suppliers/SupplierForm'
import { ConfirmAccount } from './ConfirmAccount'
import { MailSettings } from './features/mailing/MailSettings'
import { ResetPassword } from './ResetPassword'
import { SupplierDevices } from './features/supplierdevices/SupplierDevices'
import { EditSupplierDevice } from './features/supplierdevices/EditSupplierDevice'
import { CreateSupplierDevice } from './features/supplierdevices/CreateSupplierDevice'
import { ProjectsList } from './features/project/ProjectsList'
import { MaterialCardList } from './features/project/materialCards/MaterialCardList'
import { CreateMaterialCard } from './features/project/materialCards/CreateMaterialCard'
import { MaterialCardForm } from './features/project/materialCards/MaterialCardForm'
import { ProjectForm } from './features/project/ProjectForm'
import { ExternalOfferForm } from './features/externalOffer/ExternalOfferForm'
import { CostEstimationComponents } from './features/supplierdevices/CostEstimationComponents'
import { CreateCostEstimationComponents } from './features/supplierdevices/CreateCostEstimationComponents'
import { EditCostEstimationComponents } from './features/supplierdevices/EditCostEstimationComponents'
import { ForgotPassword } from './ForgotPassword'
import { ProjectProcessForm } from './features/project/projectProcess/ProjectProcessForm'
import { CreateProject } from './features/project/CreateProject'
import { ProjectProcessList } from './features/project/projectProcess/ProjectProcessList'
import { SupplierOfferList } from './features/mockups/SupplierOfferList'
import { SupplierBudgetOfferList } from './features/mockups/SupplierBudgetOfferList'
import { SupplierBudgetOffer } from './features/mockups/SupplierBudgetOffer'
import { SupplierOfferForm } from './features/mockups/SupplierOfferForm'
import { MeetingMinutesForm } from './features/project/mettingMinutes/MeetingMinutesForm'
import { CreateMeeting } from './features/project/mettingMinutes/CreateMeeting'
import { BusinessCaseList } from './features/businessCase/BusinessCaseList'
import { CreateBusinessCase, EditBusinessCase } from './features/businessCase/BusinessCase'
import { If } from './app/If'
import { selectModule } from './features/modules/moduleSlice'
import { ChcList } from './features/chc/ChcList'
import { Chc } from './features/chc/Chc'
import { CompaniesToRegister } from './features/companyToRegister/CompaniesToRegister'
import { SystemSettingsPage } from './features/systemSettings/SystemSettings'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { DiffTest } from './app/DiffTest'
import { DeviceSpec } from './features/specs/DeviceSpec'
import { SystemEvents } from './features/systemSettings/systemEvents/SystemEvents'
import { CompanyArchivedUsers } from './features/users/CompanyArchivedUsers'
import { ArchivedUsers } from './features/users/ArchivedUsers'
import { UserDetails } from './features/users/UserDetails'
import { DocumentsOverview } from './features/documents/system/DocumentsOverview'
import { RiskAnalysisList } from './features/documents/riskAnalysis/RiskAnalysisList'
import { CreateRiskAnalysis } from './features/documents/riskAnalysis/CreateRiskAnalysis'
import { RiskAnalysisForm } from './features/documents/riskAnalysis/RiskAnalysisForm'
import { ClientAIQuestions } from './features/ai/ClientAIQuestions'
import { MeasurementsList } from './features/measurements/MeasurementsList'
import { MeasurementForm } from './features/measurements/MeasurementForm'
import { SpecList } from './features/specs/SpecList'
import { CreateSpec } from './features/specs/CreateSpec'
import { EditSpec } from './features/specs/EditSpec'
import { CompanySettings } from './features/administration/companySettings/CompanySettings'
import { SpecImport } from './features/specs/SpecImport'
import SupplierFSMockup from './features/mockups/SupplierFSMockup'
import SupplierDSMockup from './features/mockups/SupplierDSMockup'


const App = () => {
    const dispatch = useAppDispatch();
    const { currentModule } = useAppSelector(selectModule)

    useEffect(() => {
        dispatch(loadTokenFromStorage());
    }, [dispatch]);

    return <>
        <Notifications />
        <BrowserRouter>
            {/* <CompatRouter> */}
            <Routes>
                <Route path="/" element={
                    <If condition={currentModule != null && (currentModule.code === "project" || currentModule.code === "offering")} otherwise={
                        <AppContainer module='*' title="menu-my-tasks">
                            <MyTasks />
                        </AppContainer>
                    }>
                        <If condition={currentModule != null && currentModule.code === "project"}>
                            <AppContainer module='project' title="menu-projects-list">
                                <ProjectsList />
                            </AppContainer>
                        </If>
                        <If condition={currentModule != null && currentModule.code === "offering"}>
                            <AppContainer module='offering' title="menu-urs-offers">
                                <UrsOfferList />
                            </AppContainer>
                        </If>
                        <If condition={currentModule != null && currentModule.code === "offering"}>
                            <AppContainer module='offering' title="menu-urs-offers">
                                <UrsOfferList />
                            </AppContainer>
                        </If>
                    </If>
                } />
                <Route path="/login" element={
                    <Login />
                } />
                <Route path="/confirmAccount" element={
                    <ConfirmAccount />
                } />
                <Route path="/resetpsw" element={
                    <ResetPassword />
                } />
                <Route path="/forgotpsw" element={
                    <ForgotPassword />
                } />
                <Route path="/externaloffer" element={
                    <ExternalOfferForm />
                } />
                <Route path="/devices" element={
                    <AppContainer module='validation' title="menu-devices">
                        <Devices />
                    </AppContainer>
                } />
                <Route path="/devices/create" element={
                    <AppContainer module='validation' title="devices-new">
                        <DeviceForm />
                    </AppContainer>
                } />
                <Route path="/devices/edit/:id" element={
                    <AppContainer module='validation' title="devices-edit">
                        <DeviceForm />
                    </AppContainer>
                } />
                <Route path="/process" element={
                    <AppContainer module='validation' title="menu-processes">
                        <Process />
                    </AppContainer>
                } />
                <Route path="/process/new" element={
                    <AppContainer module='validation' title="process-new">
                        <CreateProcess />
                    </AppContainer>
                } />
                <Route path="/process/dashboard/:id" element={
                    <AppContainer module='validation' title="menu-process-dashboard">
                        <ProcessDashboard2 />
                    </AppContainer>
                } />
                <Route path="/process/stage/:stage/:id" element={
                    <AppContainer module='validation' title="menu-process-stage-overview">
                        <ProcessStage />
                    </AppContainer>
                } />
                <Route path="/IQ/test/edit/:processId/:id" element={
                    <AppContainer module='validation' title="iq-test-edit">
                        <TestEdit stage="IQ" />
                    </AppContainer>
                } />
                <Route path="/IQ/test/create/:processId" element={
                    <AppContainer module='validation' title="iq-test-create">
                        <TestCreate stage="IQ" />
                    </AppContainer>
                } />
                <Route path="/IQ/test/result/:processId/:id" element={
                    <AppContainer module='validation' title="iq-test-result">
                        <TestResult stage="IQ" />
                    </AppContainer>
                } />
                <Route path="/DQ/test/edit/:processId/:id" element={
                    <AppContainer module='validation' title="dq-test-edit">
                        <TestEdit stage="DQ" />
                    </AppContainer>
                } />
                <Route path="/DQ/test/create/:processId" element={
                    <AppContainer module='validation' title="dq-test-create">
                        <TestCreate stage="DQ" />
                    </AppContainer>
                } />
                <Route path="/DQ/test/result/:processId/:id" element={
                    <AppContainer module='validation' title="dq-test-result">
                        <TestResult stage="DQ" />
                    </AppContainer>
                } />
                <Route path="/OQ/test/edit/:processId/:id" element={
                    <AppContainer module='validation' title="oq-test-edit">
                        <TestEdit stage="OQ" />
                    </AppContainer>
                } />
                <Route path="/OQ/test/create/:processId" element={
                    <AppContainer module='validation' title="oq-test-create">
                        <TestCreate stage="OQ" />
                    </AppContainer>
                } />
                <Route path="/OQ/test/result/:processId/:id" element={
                    <AppContainer module='validation' title="oq-test-result">
                        <TestResult stage="OQ" />
                    </AppContainer>
                } />
                <Route path="/PQ/test/edit/:processId/:id" element={
                    <AppContainer module='validation' title="pq-test-edit">
                        <TestEdit stage="PQ" />
                    </AppContainer>
                } />
                <Route path="/PQ/test/create/:processId" element={
                    <AppContainer module='validation' title="pq-test-create">
                        <TestCreate stage="PQ" />
                    </AppContainer>
                } />
                <Route path="/PQ/test/result/:processId/:id" element={
                    <AppContainer module='validation' title="pq-test-result">
                        <TestResult stage="PQ" />
                    </AppContainer>
                } />
                <Route path="/devicetypes" element={
                    <AppContainer module='admin' title="menu-dev-types">
                        <DeviceTypes />
                    </AppContainer>
                } />
                <Route path="/structure" element={
                    <AppContainer module='super' title="menu-structure">
                        <StructureTabs />
                    </AppContainer>
                } />
                <Route path="/deviceType/new" element={
                    <AppContainer module='admin' title="device-type-new">
                        <CreateDeviceType />
                    </AppContainer>
                } />
                <Route path="/deviceType/edit/:id" element={
                    <AppContainer module='admin' title="device-type-edit">
                        <EditDeviceType />
                    </AppContainer>
                } />
                <Route path="/diff/test" element={
                    <AppContainer module='admin' title="diff-test">
                        <DiffTest />
                    </AppContainer>
                } />
                <Route path="/logout" element={
                    <Logout />
                } />
                <Route path="/settings" element={
                    <AppContainer module='*' title="menu-settings">
                        <Settings />
                    </AppContainer>
                } />
                <Route path="/companies" element={
                    <AppContainer module='super' title="menu-companies">
                        <Companies />
                    </AppContainer>
                } />
                <Route path="/company/create" element={
                    <AppContainer module='super' title="companies-new">
                        <CompanyForm />
                    </AppContainer>
                } />
                <Route path="/company/edit/:id" element={
                    <AppContainer module='super' title="companies-edit">
                        <CompanyForm />
                    </AppContainer>
                } />
                <Route path="/company/users" element={
                    <AppContainer module='admin' title="menu-users">
                        <CompanyUsers />
                    </AppContainer>
                } />
                <Route path="/company/archived-users" element={
                    <AppContainer module='admin' title="menu-archived-users">
                        <CompanyArchivedUsers />
                    </AppContainer>
                } />
                <Route path="/company/user/details/:id" element={
                    <AppContainer module='admin' title="menu-user-details">
                        <UserDetails />
                    </AppContainer>
                } />
                <Route path="/company/user/create" element={
                    <AppContainer module='admin' title="user-new">
                        <CreateCompanyUser />
                    </AppContainer>
                } />
                <Route path="/company/user/edit/:id" element={
                    <AppContainer module='admin' title="user-edit">
                        <CreateCompanyUser />
                    </AppContainer>
                } />
                <Route path="/company/settings/" element={
                    <AppContainer module='admin' title="company-settings">
                        <CompanySettings />
                    </AppContainer>
                } />

                <Route path="/users" element={
                    <AppContainer module='super' title="menu-users">
                        <Users />
                    </AppContainer>
                } />
                <Route path="/archived-users" element={
                    <AppContainer module='super' title="menu-archived-users">
                        <ArchivedUsers />
                    </AppContainer>
                } />
                <Route path="/user/details/:id" element={
                    <AppContainer module='super' title="menu-user-details">
                        <UserDetails />
                    </AppContainer>
                } />
                <Route path="/user/create" element={
                    <AppContainer module='super' title="user-new">
                        <CreateUser />
                    </AppContainer>
                } />
                <Route path="/user/edit/:id" element={
                    <AppContainer module='super' title="user-edit">
                        <CreateUser />
                    </AppContainer>
                } />
                <Route path="/validations" element={
                    <AppContainer module='validation' title="menu-validations"><Validations /></AppContainer>
                } />
                <Route path="/validation/new" element={
                    <AppContainer module='validation' title="validation-new"><CreateValidation /></AppContainer>
                } />
                <Route path="/validation/edit/:id" element={
                    <AppContainer module='validation' title="validation-edit"><EditValidation /></AppContainer>
                } />
                <Route path="/validation/preview/:id" element={
                    <AppContainer module='validation' title="validation-edit"><ViewValidation /></AppContainer>
                } />
                <Route path="/sys/validations" element={
                    <AppContainer module='super' title="menu-validations"><Validations /></AppContainer>
                } />
                <Route path="/sys/validation/new" element={
                    <AppContainer module='super' title="validation-new"><CreateValidation /></AppContainer>
                } />
                <Route path="/sys/validation/edit/:id" element={
                    <AppContainer module='super' title="validation-edit"><EditValidation /></AppContainer>
                } />
                <Route path="/urs" element={
                    <AppContainer module='urs' title="menu-urs">
                        <UrsList />
                    </AppContainer>
                } />
                <Route path="/new-risk-analysis" element={
                    <AppContainer module='urs' title="risk-analysis">
                        <RiskAnalysisList />
                    </AppContainer>
                } />
                <Route path="/new-risk-analysis/create" element={
                    <AppContainer module='urs' title="risk-analysis-creator">
                        <CreateRiskAnalysis />
                    </AppContainer>
                } />
                <Route path="/new-risk-analysis/edit/:id" element={
                    <AppContainer module='urs' title="risk-analysis">
                        <RiskAnalysisForm />
                    </AppContainer>
                } />
                <Route path="/urs/create" element={
                    <AppContainer module='urs' title="urs-create">
                        <CreateURS />
                    </AppContainer>
                } />

                <Route path="/urs/edit/:id" element={
                    <AppContainer module='urs' title="urs-edit">
                        <EditURS template={false} view='form' />
                    </AppContainer>
                } />
                <Route path="/urs/diff/:id" element={
                    <AppContainer module='urs' title="urs-edit">
                        <EditURS template={false} view='diff' />
                    </AppContainer>
                } />
                <Route path="/ursOffers" element={
                    <AppContainer module='offering' title="menu-urs-offers">
                        <UrsOfferList />
                    </AppContainer>
                } />
                <Route path="/ursOffers/edit/:id" element={
                    <AppContainer module='offering' title="menu-urs-offers">
                        <UrsOfferForm mode="edit" />
                    </AppContainer>
                } />
                <Route path="/specs" element={
                    <AppContainer module='offering' title="menu-specs">
                        {/* <DeviceSpec /> */}
                        <SpecList />
                    </AppContainer>
                } />
                <Route path="/specs/create" element={
                    <AppContainer module='offering' title="menu-specs-create">
                        {/* <DeviceSpec /> */}
                        <CreateSpec />
                    </AppContainer>
                } />
                <Route path="/specs/edit/:id" element={
                    <AppContainer module='offering' title="menu-specs-edit">
                        {/* <DeviceSpec /> */}
                        <EditSpec />
                    </AppContainer>
                } />
                <Route path="/specs/import" element={
                    <AppContainer module='offering' title="menu-specs-import">
                        <SpecImport />
                    </AppContainer>
                } />
                <Route path="/fs-document" element={
                    <AppContainer module='offering' title="menu-fs-document">
                        <SupplierFSMockup />
                    </AppContainer>
                } />
                <Route path="/ds-document" element={
                    <AppContainer module='offering' title="menu-ds-document">
                        <SupplierDSMockup />
                    </AppContainer>
                } />

                <Route path="/offer-preview/:id" element={
                    <AppContainer module='urs' title="menu-urs-offers">
                        <UrsOfferForm mode="preview" />
                    </AppContainer>
                } />
                <Route path="/requirementsTypes" element={
                    <AppContainer module='super' title="menu-requirements-types">
                        <RequirementsTypesList />
                    </AppContainer>
                } />
                <Route path="/requirementsTypes/new" element={
                    <AppContainer module='super' title="requirements-type-new">
                        <RequirementsTypesForm />
                    </AppContainer>
                } />
                <Route path="/requirementsTypes/edit/:id" element={
                    <AppContainer module='super' title="requirements-type-edit">
                        <RequirementsTypesForm />
                    </AppContainer>
                } />

                <Route path="/requirements" element={
                    <AppContainer module='urs' title="menu-requirements-dictionary">
                        <RequirementsDictList />
                    </AppContainer>
                } />
                <Route path="/sys/requirements" element={
                    <AppContainer module='super' title="menu-requirements-dictionary">
                        <RequirementsDictList />
                    </AppContainer>
                } />
                <Route path="/urstemplates" element={
                    <AppContainer module='urs' title="menu-urs-templates">
                        <UrsTemplatesList />
                    </AppContainer>
                } />
                <Route path="/urstemplates/new" element={
                    <AppContainer module='urs' title="urs-template-new">
                        <UrsTemplateForm />
                    </AppContainer>
                } />
                <Route path="/urstemplates/edit/:id" element={
                    <AppContainer module='urs' title="urs-template-edit">
                        <EditURS template={true} view='form' />
                    </AppContainer>
                } />
                <Route path="/sys/urstemplates" element={
                    <AppContainer module='super' title="menu-urs-templates">
                        <UrsTemplatesList />
                    </AppContainer>
                } />
                <Route path="/sys/urstemplates/new" element={
                    <AppContainer module='super' title="urs-template-new">
                        <UrsTemplateForm />
                    </AppContainer>
                } />
                <Route path="/sys/urstemplates/edit/:id" element={
                    <AppContainer module='super' title="urs-template-edit">
                        <EditURS template={true} view='form' />
                    </AppContainer>
                } />
                <Route path="/aisettings" element={
                    <AppContainer module='admin' title="menu-ai-settings">
                        <ClientAIQuestions />
                    </AppContainer>
                } />
                <Route path="/sys/aisettings" element={
                    <AppContainer module='super' title="menu-ai-settings">
                        <AiSettings />
                    </AppContainer>
                } />
                <Route path="/riskAnalysis/edit/:id" element={
                    <AppContainer module='urs' title="risk-analysis">
                        <EditRiskAnalysis />
                    </AppContainer>
                } />
                <Route path="/areas" element={
                    <AppContainer module='admin' title="menu-areas">
                        <Areas />
                    </AppContainer>
                } />
                <Route path="/modules/access" element={
                    <AppContainer module="super" title="menu-modules-access">
                        <ModuleAccess />
                    </AppContainer>
                } />
                <Route path="/modules" element={
                    <Authenticate>
                        <Modules />
                    </Authenticate>
                } />
                <Route path="/supplier" element={
                    <AppContainer module='admin' title="menu-supplier">
                        <Suppliers />
                    </AppContainer>
                } />
                <Route path="/suppliers/create" element={
                    <AppContainer module='admin' title="supplier-new">
                        <SupplierForm />
                    </AppContainer>
                } />
                <Route path="/suppliers/edit/:id" element={
                    <AppContainer module='admin' title="supplier-edit">
                        <SupplierForm />
                    </AppContainer>
                } />
                <Route path="/mailingsettings" element={
                    <AppContainer module='admin' title="menu-mailing-settings">
                        <MailSettings />
                    </AppContainer>
                } />
                <Route path="/sys/mailingsettings" element={
                    <AppContainer module='super' title="menu-mailing-settings">
                        <MailSettings />
                    </AppContainer>
                } />
                <Route path="/supplierdevices" element={
                    <AppContainer module='offering' title="menu-supplier-devices">
                        <SupplierDevices />
                    </AppContainer>
                } />
                <Route path="/supplierdevices/create" element={
                    <AppContainer module='offering' title="menu-supplier-devices">
                        <CreateSupplierDevice />
                    </AppContainer>
                } />
                <Route path="/supplierdevices/edit/:id" element={
                    <AppContainer module='offering' title="menu-supplier-devices">
                        <EditSupplierDevice />
                    </AppContainer>
                } />
                <Route path="/supplierOffers" element={
                    <AppContainer module='offering' title="menu-supplier-offers">
                        <SupplierOfferList />
                    </AppContainer>
                } />
                <Route path="/supplierOffers/edit/:id" element={
                    <AppContainer module='offering' title="menu-supplier-offers">
                        <SupplierOfferForm />
                    </AppContainer>
                } />
                <Route path="/supplierBudgetOffer" element={
                    <AppContainer module='offering' title="menu-supplier-budget-offers">
                        <SupplierBudgetOfferList />
                    </AppContainer>
                } />
                <Route path="/supplierBudgetOffer/edit/:id" element={
                    <AppContainer module='offering' title="menu-supplier-budget-offers">
                        <SupplierBudgetOffer />
                    </AppContainer>
                } />
                <Route path="/costestimationcomponents" element={
                    <AppContainer module='offering' title="menu-cost-estimation-components">
                        <CostEstimationComponents />
                    </AppContainer>
                } />
                <Route path="/costestimationcomponents/create" element={
                    <AppContainer module='offering' title="menu-cost-estimation-components">
                        <CreateCostEstimationComponents />
                    </AppContainer>
                } />
                <Route path="/costestimationcomponents/edit/:id" element={
                    <AppContainer module='offering' title="menu-cost-estimation-components">
                        <EditCostEstimationComponents />
                    </AppContainer>
                } />
                <Route path="/projects" element={
                    <AppContainer module='project' title="menu-projects-list">
                        <ProjectsList />
                    </AppContainer>
                } />
                <Route path="/projects/create" element={
                    <AppContainer module='project' title="menu-projects-list">
                        <CreateProject />
                    </AppContainer>
                } />
                <Route path="/projects/edit/:id" element={
                    <AppContainer module='project' title="menu-projects-list">
                        <ProjectForm />
                    </AppContainer>
                } />
                <Route path="/projectprocess/" element={
                    <AppContainer module='project' title="menu-project-process-list">
                        <ProjectProcessList />
                    </AppContainer>
                } />
                <Route path="/projectprocess/edit/:id" element={
                    <AppContainer module='project' title="menu-project-process">
                        <ProjectProcessForm />
                    </AppContainer>
                } />
                <Route path="/meetingMinutes/edit/:id" element={
                    <AppContainer module='project' title="menu-meeting-minutes">
                        <MeetingMinutesForm />
                    </AppContainer>
                } />
                <Route path="/meeting/create/:meetingMinuteId" element={
                    <AppContainer module='project' title="menu-create-meeting">
                        <CreateMeeting />
                    </AppContainer>
                } />
                <Route path="/materialcards" element={
                    <AppContainer module='project' title="menu-material-cards">
                        <MaterialCardList />
                    </AppContainer>
                } />
                <Route path="/materialcards/create" element={
                    <AppContainer module='project' title="menu-material-cards">
                        <CreateMaterialCard />
                    </AppContainer>
                } />
                <Route path="/materialcards/edit/:id" element={
                    <AppContainer module='project' title="menu-material-cards">
                        <MaterialCardForm />
                    </AppContainer>
                } />
                <Route path="/businessCases" element={
                    <AppContainer module='project' title="menu-business-cases">
                        <BusinessCaseList />
                    </AppContainer>
                } />
                <Route path="/businessCase/create" element={
                    <AppContainer module='project' title="menu-business-case-create">
                        <CreateBusinessCase />
                    </AppContainer>
                } />
                <Route path="/businessCase/edit/:id" element={
                    <AppContainer module='project' title="menu-business-case-edit">
                        <EditBusinessCase />
                    </AppContainer>
                } />
                <Route path="/changeControlDocuments" element={
                    <AppContainer module='project' title="menu-chc-list">
                        <ChcList />
                    </AppContainer>
                } />
                <Route path="/chc/edit/:id" element={
                    <AppContainer module='project' title="menu-chc-edit">
                        <Chc />
                    </AppContainer>
                } />
                <Route path="/company/system-events" element={
                    <AppContainer module='admin' title="menu-system-events">
                        <SystemEvents />
                    </AppContainer>
                } />
                <Route path="/system-settings" element={
                    <AppContainer module='super' title="menu-system-settings">
                        <SystemSettingsPage />
                    </AppContainer>
                } />
                <Route path="/companies-to-register" element={
                    <AppContainer module='super' title="menu-companies-to-register">
                        <CompaniesToRegister />
                    </AppContainer>
                } />
                <Route path="/documents-overview" element={
                    <AppContainer module='super' title="menu-documents-overview">
                        <DocumentsOverview />
                    </AppContainer>
                } />
                <Route path="/measurements" element={
                    <AppContainer module='measure' title="menu-measurements">
                        <MeasurementsList />
                    </AppContainer>
                } />
                <Route path="/measurements/edit/:id" element={
                    <AppContainer module='measure' title="menu-measurements-edit">
                        <MeasurementForm />
                    </AppContainer>
                } />
            </Routes>
            {/* </Router> */}
        </BrowserRouter>
    </>
}

export default App;
