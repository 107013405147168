import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth} from "../../http";
import { logout } from "../user/userSlice";


export type UrsOfferStatus = "N" | "R" | "A"  | "S" |"E" | "F"
export type RequirementNote = "F" | "D" | "U" | ""

export interface UrsRequirementType {
    id: AppId
    code: string
    name: string
    order: number
    groups: UrsRequirementGroup[]
}

export interface UrsRequirementGroup {
    id: AppId
    name: string
    numeration: string
    code: string
    requirements: UrsOfferRequirement[]
    groups: UrsRequirementGroup[]
}

export interface UrsOfferGroup {
    id: AppId
    name: string
    numeration: string
    code: string
    requirements: UrsOfferRequirement[]
    groups: UrsOfferGroup[]
}


export interface UrsOffer {
   id: AppId 
   supplierId: AppId  
   supplierName: string
   ursNo: string   
   ursId: AppId
   deviceTypeId: AppId
   deviceTypeName: string
   companyId: AppId
   companyName: string
   status: UrsOfferStatus  
   createDate: string   
   responseDate: string   
   comment: string
   title: string 
   area: string 
   purpose: string 
   description: string 
   ursOfferRequirements: EntityState<UrsOfferRequirement>
   supplierDeviceId: AppId | null
   guid: string
   types: UrsOfferGroup[]
}

export interface UrsOfferRequirement {
    id: string
    code: string
    name: string
    description: string
    criticality: string | null
    typeId: string
    ursId: string
    comment: string
    note: RequirementNote | null
    supplierDeviceDetailId: AppId | null
    guid: string
    responsibleUserId: AppId | null
    numeration: string | null
}

export type UrsOfferHeader = Omit<UrsOffer, "ursOfferRequirements">

export type DiscussionType = 'INTER_COMPANY' | 'PRIVATE_COMPANY'

export interface UrsOfferMessage {
    id: AppId
    ursOfferId: AppId
    ursRequirementId: AppId
    message: string
    author: string | null
    companyName: string
    userId: AppId | null
    date: string
    discussionType: DiscussionType
    status: string
    parentMessageId: AppId | null
    guid: string
    type: string
}


const adapter = createEntityAdapter<UrsOfferHeader>({
    selectId: (offer) => offer.id,
})

export const offerRequirementAdapter = createEntityAdapter<UrsOfferRequirement>({
    selectId: req => req.id,
})

export const { 
    selectAll: selectAllOfferRequirements,
    selectById: selectUOfferRequirementById,
} = offerRequirementAdapter.getSelectors()


export type UrsOfferState = EntityState<UrsOfferHeader> & { state: AppDataState, moduleId: AppId | undefined }

const initialState: UrsOfferState = adapter.getInitialState({
    state: {
        type: "empty",
    },
    moduleId: undefined,
})


export const loadUrsOfferList = createAsyncThunk<UrsOfferHeader[], AppId, AppThunkAPIType>("ursOffers/load", 
    async (moduleId, { dispatch, rejectWithValue, getState, requestId }) => {
        const state = getState().ursOffers.state
        if (state.type === "loading" && state.requestId === requestId) {
            const result = await dispatch(getWithAuth({
                url: `api/UrsOffers?moduleId=${moduleId}`
            }))
            const { payload } = result 
            if (getWithAuth.fulfilled.match(result)) {
                return payload as UrsOfferHeader[] 
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
        return []
})

const decode = (data: any): UrsOffer => {
    const initialRequirements = offerRequirementAdapter.getInitialState()
    const ursOfferRequirements = 
        data.ursOfferRequirements 
            ? offerRequirementAdapter.addMany(initialRequirements, data.ursOfferRequirements) 
            : initialRequirements   

    return {...data
        , ursOfferRequirements        
    } as UrsOffer 
}

const encode = (ursOffer: UrsOffer): any => {
return {
    ...ursOffer,
    ursOfferRequirements: selectAllOfferRequirements(ursOffer.ursOfferRequirements)    
    }
}

export const loadUrsOfferComments = createAsyncThunk<UrsOfferMessage[], AppId, AppThunkAPIType>("ursOffers/load-messages", 
    async (offerId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/UrsOfferMessage/offer/${offerId}`
        }))
        const { payload } = result 
        if (getWithAuth.fulfilled.match(result)) {
            return payload as UrsOfferMessage[] 
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const createUrsOfferComment = createAsyncThunk<UrsOfferMessage, UrsOfferMessage, AppThunkAPIType>(
    "ursOffers/post-message",
    async (comment, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOfferMessage`,
            payload: comment
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as UrsOfferMessage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateUrsOfferComment = createAsyncThunk<UrsOfferMessage, UrsOfferMessage, AppThunkAPIType>(
    "ursOffers/update-message",
    async (comment, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/UrsOfferMessage/${comment.id}`,
            payload: comment
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as UrsOfferMessage
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteUrsOfferComment = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "ursOffers/delete-message",
    async (commentId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({
            url: `api/UrsOfferMessage/${commentId}`,
        }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return commentId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadUrsOffer = createAsyncThunk<UrsOffer, AppId, AppThunkAPIType>("ursOffers/loadForUrs", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/UrsOffers/${id}`
    }))
    const { payload } = result 
    if (getWithAuth.fulfilled.match(result)) {
        
        return decode(payload)
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateUrsOffer = createAsyncThunk<UrsOffer, UrsOffer, AppThunkAPIType>("ursOffers/update", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(putWithAuth({
        url: `api/ursOffers/${data.id}`,
        payload: encode(data),
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return decode(data)
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const loadOffersForUrs = createAsyncThunk<UrsOffer[], AppId, AppThunkAPIType>("ursOffers/loadForUrs", async (ursId, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/UrsOffers/find/${ursId}`
    }))
    const { payload } = result 
    if (getWithAuth.fulfilled.match(result)) {
        return payload as UrsOffer[] 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const rejectOffer = createAsyncThunk<UrsOffer, {offerId: AppId, comment: string}, AppThunkAPIType>(
    "ursOffers/reject-offer",
    async ({ offerId, comment } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/reject/${offerId}`,
            payload: {
                comment,
            }
        }))
        const { payload } = result
        
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }   
    }
)

export const sendToEvaluation = createAsyncThunk<UrsOffer, {offerId: AppId, comment: string}, AppThunkAPIType>(
    "ursOffers/evaluation",
    async ({ offerId, comment } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/evaluation/${offerId}`,
            payload: {
                comment,
            }
        }))
        const { payload } = result
        
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }   
    }
)


export const autoMatchOffer = createAsyncThunk<UrsOffer, AppId, AppThunkAPIType>(
    "ursOffers/propose-matching",
    async ( offerId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/propose-matching/${offerId}`,
            payload: { }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const sendOffer = createAsyncThunk<UrsOffer, AppId, AppThunkAPIType>(
    "ursOffers/send-offer",
    async ( offerId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/send/${offerId}`,
            payload: { }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const assignResponsibleUser
    = createAsyncThunk<UrsOffer, {offerId: AppId, requirementId: AppId, userId: AppId}, AppThunkAPIType>(
    "ursOffers/add-responsible",
    async ({ offerId, requirementId, userId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/${offerId}/add-responsible`,
            payload: { responsibleUserId: userId, requirementId }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const matchRequirementWithOffer 
    = createAsyncThunk<UrsOffer, {offerId: AppId, requirementId: AppId, supplierDeviceDetailId: AppId}, AppThunkAPIType>(
    "ursOffers/match-with-offer", async ({ offerId, requirementId, supplierDeviceDetailId }, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/UrsOffers/${offerId}/add-matching`,
        payload: { requirementId, supplierDeviceDetailId }
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return decode(payload)
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export interface UrsOfferRequirementNote {
    id: AppId
    requirementId: AppId
    note: string | null
    comment: string
}
export const postUrsOfferRequirementNote 
    = createAsyncThunk<UrsOfferRequirement, UrsOfferRequirementNote, AppThunkAPIType>(
    "ursOffers/post-note-with-comment", 
    async ({ id, requirementId, note, comment }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/UrsOffers/${id}/add-comment`,
            payload: {
            requirementId,
            note,
            comment,
            }
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as UrsOfferRequirement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const ursOfferSlice = createSlice({
    name: "ursOffer",
    initialState,
    reducers: {
        clearUrsOfferSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadUrsOfferList.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadUrsOfferList.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
                state.moduleId = action.meta.arg
            }
        })
        builder.addCase(loadUrsOfferList.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(updateUrsOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(rejectOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
         builder.addCase(sendToEvaluation.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(sendOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(autoMatchOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        
        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectUrsOffers = (state: RootState): UrsOfferState => state.ursOffers

export const 
    { selectAll: selectAllUrsOffers
    , selectById: selectUrsOfferById 
    , selectTotal: selectTotalUrsOffers
    } = adapter.getSelectors<RootState>(selectUrsOffers)

export default ursOfferSlice.reducer;
